import {JoinResponse} from "../models/JoinResponse";
import {PrepareResponse} from "../models/PrepareResponse";
import {StartedEvent} from "../models/StartedEvent";
import {UserWinResponse} from "../models/UserWinResponse";
import {PauseResponse} from "../models/PauseResponse";
import {ChangeBetResponse} from "../models/ChangeBetResponse";
import {ChangeProductResponse} from "../models/ChangeProductResponse";
import {ChangeBetErrorResponse} from "../models/ChangeBetErrorResponse";
import {UsersResponse} from "../models/UsersResponse";
import {UserDisconnectResponse} from "../models/UserDisconnectResponse";

export class EventResponseConverter {

    convertJoinEvent(data: any): JoinResponse {
        let time = ""
        if (data.startDate === "" && data.startTime === "") {
            time = "в указанное админом время"
        } else {
            time = data.startTime
        }
        return {
            startTime: time,
            rate: data.rate,
            price: data.price,
            betStep: data.betStep,
            product: data.product,
            products: data.products,
            timeLeft: data.timeLeft,
            startDate: data.startDate,
            auctionState: data.auctionState,
            productsSize: data.productsSize,
        }
    }

    convertChangeBetError(data: any): ChangeBetErrorResponse {
        return {
            type: data.type,
            message: data.message
        }
    }

    convertUserWin(data: any): UserWinResponse {
        return {
            type: data.type,
            price: data.price,
            product: data.product,
            isSoldProduct: data.isSoldProduct,
        }
    }

    convertPauseEvent(data: any): PauseResponse {
        return {
            type: data.type,
            isPaused: Boolean(data.isPaused)
        }
    }

    convertChangeBetEvent(data: any): ChangeBetResponse {
        return {
            type: data.type,
            price: data.price
        }
    }

    convertChangeProductEvent(data: any): ChangeProductResponse {
        return {
            type: data.type,
            product: data.product
        }
    }

    convertUsersEvent(data: any): UsersResponse {
        return {
            type: data.type,
            users: data.users,
        }
    }

    convertUserDisconnectEvent(data: any): UserDisconnectResponse {
        return {
            type: data.type,
            userId: data.userId,
            userSid: data.userSid
        }
    }

    convertPreparingEvent(data: any): PrepareResponse {
        return {
            delay: data.delay,
            type: data.type,
            products: data.products,
        }
    }

    convertStartedEvent(data: any): StartedEvent {
        return {
            timeLeft: data.timeLeft,
            product: data.product,
            currentBet: data.currentBet,
            productsSize: data.productsSize,
            price: data.price,
            betStep: data.betStep,
            products: data.products,
            type: data.type
        }
    }

}