import {useEffect, useState} from "react";
import {initSettingsState, SettingsState} from "./SettingsState";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {useAlert} from "../main/AlertManager";
import {
    useDeleteAllUsersMutation,
    useHandleAuctionMutation,
    useLoadConfigMutation,
    useUpdateConfigMutation
} from "../../data/config/ConfigApi";
import {ConfigResponse} from "../../data/config/responses/ConfigResponse";
import {AuthExceptionsConverter} from "../../domain/errors/AuthExceptionsConverter";
import {useNavigate} from "react-router-dom";
import {CaveCavistaRoutes} from "../../routing/CaveCavistaRoutes";

export default function SettingsViewModel() {

    const [state, setState] = useState<SettingsState>(initSettingsState())

    const [loadConfigMutation] = useLoadConfigMutation()
    const [updateConfigMutation] = useUpdateConfigMutation()
    const [updateAuctionMutation] = useHandleAuctionMutation()
    const [deleteAllUsersMutation] = useDeleteAllUsersMutation()

    const authErrorConverter = new AuthExceptionsConverter()
    const showAlert = useAlert()

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    function fetchData() {

        async function getSingleProduct() {
            return await loadConfigMutation().unwrap()
        }

        getSingleProduct().then((config: ConfigResponse) => {
            setState({
                ...state,
                data: {
                    ...state.data,
                    canStartAuction: config.canStartAuction,
                    isAuctionStarted: config.auctionState === "STARTED",
                    dateText: config.startDate ?? "",
                    time: config.startTime ?? "",
                    betTime: config.betTime?.toString() ?? "",
                    rate: config.rate?.toString() ?? "100.1",
                    delay: config.delayBeforeStart?.toString() ?? "10",
                    step: config.betStep?.toString() ?? "20"
                },
                isLoading: false,
                error: null
            })
        }).catch((e) => {
            setState({
                ...state,
                isLoading: false,
                error: authErrorConverter.convert(e)
            })
        })
    }

    const onDateChanged = (date: Date | undefined) => {
        setState({
            ...state,
            data: {
                ...state.data,
                date: date,
                dateText: date ? format(date, "PPP", {locale: ru}) : ""
            }
        })
    }

    const onDateTextChanged = (date: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                dateText: date
            }
        })
    }

    const onTimeChanged = (time: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                time: time
            }
        })
    }

    const onDelayChanged = (delay: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                delay: delay
            }
        })
    }

    const onBetTimerChanged = (betTime: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                betTime: betTime
            }
        })
    }

    const onStepChanged = (step: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                step: step
            }
        })
    }

    const onRateChanged = (rate: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                rate: rate
            }
        })
    }

    const onDeleteAllUsersFromProductsClicked = async () => {
        try {
            const payload = await deleteAllUsersMutation().unwrap()
            if (payload.success) {
                setState({
                    ...state,
                    data: {
                        ...state.data,
                        canStartAuction: true
                    }
                })
                showAlert("Все ваши лоты теперь снова можно выставлять на аукцион", 'success')
            }
        } catch (e) {
            showAlert("Ошибка при сохранении настроек", 'error')
        }
    }

    const onSaveClicked = async () => {
        try {
            const payload = await updateConfigMutation({
                startDate: state.data.dateText,
                step: Number(state.data.step),
                delay: Number(state.data.delay),
                betTime: Number(state.data.betTime),
                rate: Number(state.data.rate),
                startTime: state.data.time
            }).unwrap()
            if (payload.success) {
                showAlert("Настройки обновлены", 'success')
            }
        } catch (e) {
            showAlert("Ошибка при сохранении настроек", 'error')
        }
    }

    const onStartAuctionClicked = async () => {
        try {
            navigate(CaveCavistaRoutes.auction, {state: {start: true}})
        } catch (e) {
            showAlert("Ошибка: аукцион не начался", 'error')
        }
    }

    const onFinishAuctionClicked = async () => {
        try {
            const payload = await updateAuctionMutation({state: "FINISH"}).unwrap()
            if (payload.success) {
                setState({
                    ...state,
                    data: {
                        ...state.data,
                        isAuctionStarted: false
                    }
                })
                showAlert("Аукцион завершен успешно", 'success')
            }
        } catch (e) {
            showAlert("Ошибка: аукцион не завершился", 'error')
        }
    }

    const onReloadPageClicked = () => {
        fetchData()
    }

    return {
        state,
        onDateChanged,
        onTimeChanged,
        onStepChanged,
        onSaveClicked,
        onRateChanged,
        onDelayChanged,
        onBetTimerChanged,
        onDateTextChanged,
        onReloadPageClicked,
        onStartAuctionClicked,
        onFinishAuctionClicked,
        onDeleteAllUsersFromProductsClicked
    }

}