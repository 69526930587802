import useViewModel from "./SellsViewModel"
import {useLottie} from "lottie-react";
import {defaultLoadingOptions} from "../../ui/CommonItems";
import {CavistaInput} from "../../ui/CavistaInput";
import React from "react";
import {SellsTable} from "./SellsTable";
import {Download} from "./DownloadData";

export function SellsPage() {
    const {state, price, priceWith, search, setSearch, onReloadPageClick} = useViewModel()
    const {View} = useLottie(defaultLoadingOptions);
    return (<div className="bg-black pt-2">
        <div className="flex lg:flex-row flex-col justify-between items-center ml-4 mr-4 pt-4 pb-4 relative pt-4">
            <div>
                <h1 className="text-xl font-bold">Страница купленных лотов</h1>
                {!state.isLoading && state.error == null && <div className="flex">
                    <div
                        className="text-white items-center flex rounded-xl pt-2 pb-2 pr-4">
                        Итого: {price} €
                    </div>
                    <div
                        className="text-white items-center flex rounded-xl pt-2 pb-2 pr-4">
                        С коммисией 5% : {priceWith.toFixed(1)} €
                    </div>
                </div>}
            </div>
            {!state.isLoading && state.error === null && <div
                className="hidden lg:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[360px] pb-2">
                <CavistaInput
                    value={search}
                    text={"Поиск вина"}
                    onChanged={(item) => setSearch(item)}
                />
            </div>}
            <div className="flex space-x-2">
                <Download data={state.items}/>
            </div>
        </div>
        {(!state.isEmpty && !state.isLoading && state.error == null) && <div>
            {!state.isLoading && state.error === null &&
                <div className="block lg:hidden pl-4 pr-4 w-full pb-2">
                    <input
                        className="w-full bg-[#191919] p-4 rounded-2xl text-white outline-gray-400 outline-0"
                        placeholder={"Поиск вина"}
                        value={search}
                        onChange={(field) => setSearch(field.target.value)}
                    />
                </div>}
            <SellsTable users={state.items}/>
        </div>}
        {state.isLoading && <div
            className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {View}
        </div>}
        {state.isEmpty && <div
            className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#818181] uppercase text-xl">
            Пока нет купленных лотов
        </div>}
        {state.error !== null && <div
            className="absolute z-20 space-y-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h2 className="text-white">Ошибка: {state.error}</h2>
            <button
                className="w-full bg-[#6C1414] rounded-md p-2 text-white active:opacity-100 focus:opacity-100"
                onClick={onReloadPageClick}>Перезагрузить
            </button>
        </div>}
    </div>)
}