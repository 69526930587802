import {useState} from "react";
import {initAuthState} from "./AuthorizationState";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {authSlice, updateAuth} from "../../data/auth/AuthSlice";
import validator from "email-validator";
import {CaveCavistaRoutes} from "../../routing/CaveCavistaRoutes";
import {useCookies} from "react-cookie";
import {useLoginMutation} from "../../data/auth/AuthApi";
import {AuthExceptionsConverter} from "../../domain/errors/AuthExceptionsConverter";

export const minPasswordLength: number = 6

export default function AuthorizationViewModel() {

    const [state, setState] = useState(initAuthState())
    const [error, setError] = useState<string>("")
    const [, setCookie] = useCookies(['token'])

    const [loginMutation] = useLoginMutation()

    const authErrorConverter = new AuthExceptionsConverter()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onLoginClicked = async () => {
        try {
            const login = state.login
            const password = state.password
            const payload = await loginMutation({login, password}).unwrap()
            dispatch(updateAuth(payload))
            setCookie('token', payload.token, {httpOnly: true})
            navigate(CaveCavistaRoutes.products, {replace: true})
        } catch (exception: any) {
            setError(authErrorConverter.convert(exception))
        }
    }

    function isEmailAndPasswordValid(email: string, password: string): boolean {
        let isErrorNotFound = true
        if (email.length === 0) {
            setError("Заполните почту")
            isErrorNotFound = false
        }
        if (!validator.validate(email)) {
            setError("Невалидная почта")
            isErrorNotFound = false
        }
        if (password.length <= minPasswordLength) {
            setError("Пароль минимально 6 символов")
            isErrorNotFound = false
        }
        return isErrorNotFound
    }

    const onLoginChanged = (login: string) => {
        setState({
            ...state,
            login: login,
        })
        setError("")
    }

    const onPasswordChanged = (password: string) => {
        setState({
            ...state,
            password: password
        })
        setError("")
    }

    const onPasswordVisibilityClick = () => {
        setState({
            ...state,
            isPasswordVisible: !state.isPasswordVisible
        })
    }

    return {
        state,
        error,
        onLoginChanged,
        onLoginClicked,
        onPasswordChanged,
        onPasswordVisibilityClick
    }

}