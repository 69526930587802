import React, {useState} from "react";
import icMenu from "../../assets/burger.svg";
import {MobileDrawerComponent} from "../main/MobileDrawerComponent";
import {useNavigate} from "react-router-dom";
import {CaveCavistaRoutes} from "../../routing/CaveCavistaRoutes";
import logo from "../../assets/logo.svg";
import {ProfileMenuWidget} from "../../ui/ProfileMenuWidget";
import {AuctionState} from "./models/AuctionState";
import {ItemUi} from "../products/main/ItemUi";

export interface AuctionHeaderProps {
    timer: string
    item: ItemUi
    state: string
    size : number
}

export function AuctionHeader({timer, state, item, size}: AuctionHeaderProps) {
    const [open, isOpen] = useState(false)
    const navigate = useNavigate()
    return <>
        <div className="block lg:hidden relative flex items-center justify-between bg-black h-20">
            <div
                onClick={() => isOpen(!open)}
                className="pl-4">
                <img src={icMenu}/>
            </div>
            {state !== AuctionState.STARTED && <div
                onClick={() => navigate(CaveCavistaRoutes.auction)}
                className="flex items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-shrink-0 m-auto cursor-pointer space-x-2 text-white">
                <img src={logo} width="24px" height="24px"/>
                <h1 className="font-semibold text-xl tracking-tight text-[#6C1414]">Cave Cavista</h1>
            </div>}
            {state === AuctionState.STARTED && <div className="flex-col justify-center text-white">
                <h2 className="text-2xl text-center">ЛОТ № {item.index} из {size}</h2>
                <div className="flex justify-center space-x-2 font-bold">
                    <h1 className="text-xl">Таймер ставки:</h1>
                    <h1 className="text-xl">{timer}</h1>
                </div>
            </div>}
            <div
                onClick={() => navigate(CaveCavistaRoutes.purchased)}
                className="flex justify-between">
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                    <ProfileMenuWidget/>
                </div>
            </div>
            <MobileDrawerComponent isOpen={open} setIsOpen={isOpen}/>
        </div>
        <div className="w-full h-0.5 bg-[#191919] absolute bottom-1"/>
    </>
}