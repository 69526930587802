export interface SingleUserState {
    data: SingleUserData
    primaryButtonText: string
    error: string | null
    isLoading: boolean
}

export interface SingleUserData {
    id: number | null
    fullName: string
    login: string
    address: string
    role: string
    title: string
    password: string
    contacts: string
}

export function initSingleUserPage(): SingleUserState {
    return {
        data: {
            id: null,
            login: "",
            address: "",
            role: "Обычный пользователь",
            title: "Создание пользователя",
            fullName: "",
            password: "",
            contacts: "",
        },
        primaryButtonText: "",
        isLoading: true,
        error: null
    }
}