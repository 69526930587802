import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ServerUrl} from "../../constants/Constants";
import {ProductsResponse} from "./responses/ProductsResponse";
import {RootState} from "../../domain/store";
import {ProductResponse} from "./responses/ProductResponse";
import {GetSingleProductRequest} from "./requests/GetSingleProductRequest";
import {DeleteProductRequest} from "./requests/DeleteProductRequest";
import {SuccessResponse} from "../common/SuccessResponse";
import {DeleteImageRequest} from "./requests/DeleteImageRequest";
import {SearchProductsRequest} from "./requests/SearchProductsRequest";
import {ImageResponse} from "./responses/ImageResponse";
import {GetProductsRequest} from "./requests/GetProductsRequest";

export const ProductsApi = createApi({
    reducerPath: 'cavista/products', baseQuery: fetchBaseQuery({
        baseUrl: `${ServerUrl}/products`, prepareHeaders: (headers, {getState}) => {
            const slice = (getState() as RootState).auth
            if (slice.token) {
                headers.set('token', `${slice.token}`)
            }
            return headers
        }
    }), endpoints: build => ({
        loadProducts: build.mutation<ProductsResponse, GetProductsRequest>({
            query: (body) => ({
                url: `/all`, method: `GET`, params: {
                    offset: body.offset,
                    sort: body.sort
                }
            }),
            transformResponse: (response: ProductsResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        loadUserProducts: build.mutation<ProductsResponse, void>({
            query: (body) => ({
                url: `/user`, method: `GET`, body
            }),
            transformResponse: (response: ProductsResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        loadSellsProducts: build.mutation<ProductsResponse, void>({
            query: (body) => ({
                url: `/sells`, method: `GET`, body
            }),
            transformResponse: (response: ProductsResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        searchProducts: build.mutation<ProductsResponse, SearchProductsRequest>({
            query: (body) => ({
                url: `/search`, method: `GET`, params: {
                    query: body.query,
                    onlySells: body.onlySells
                }
            }),
            transformResponse: (response: ProductsResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        loadSingleProduct: build.mutation<ProductResponse, GetSingleProductRequest>({
            query: (body) => ({
                url: `/single`, method: `GET`, params: {
                    productId: body.productId
                }
            }),
            transformResponse: (response: ProductResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        createProduct: build.mutation<ProductResponse, FormData>({
            query: (body) => ({
                url: `/create`, method: `POST`, body
            }),
            transformResponse: (response: ProductResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        addImage: build.mutation<ImageResponse, FormData>({
            query: (body) => ({
                url: `/addImage`, method: `POST`, body
            }),
            transformResponse: (response: ImageResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        deleteImage: build.mutation<SuccessResponse, DeleteImageRequest>({
            query: (body) => ({
                url: `/deleteImage`, method: `DELETE`, params: {
                    productId: body.productId,
                    imageId: body.imageId,
                    isAvatar: body.isAvatar
                }
            }),
            transformResponse: (response: SuccessResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        updateProduct: build.mutation<ProductResponse, FormData>({
            query: (body) => ({
                url: `/update`, method: `POST`, body
            }),
            transformResponse: (response: ProductResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        deleteProduct: build.mutation<SuccessResponse, DeleteProductRequest>({
            query: (body) => ({
                url: `/delete`, method: `DELETE`, params: {
                    productId: body.productId
                }
            }),
            transformResponse: (response: SuccessResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
    })
})

export const {
    useAddImageMutation,
    useDeleteImageMutation,
    useLoadProductsMutation,
    useCreateProductMutation,
    useDeleteProductMutation,
    useUpdateProductMutation,
    useSearchProductsMutation,
    useLoadUserProductsMutation,
    useLoadSingleProductMutation,
    useLoadSellsProductsMutation,
} = ProductsApi