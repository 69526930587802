import useViewModel from "./AuthorizationViewModel"
import {CavistaInput} from "../../ui/CavistaInput"
import authVino from "../../assets/auth_vino.jpg"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export function AuthorizationPage() {
    const {state, error, onPasswordChanged, onLoginChanged, onLoginClicked, onPasswordVisibilityClick} = useViewModel()
    return (<div className="relative w-screen h-screen bg-black flex justify-center items-center">
        <h1 className="text-center top-32 absolute">Cave Cavista</h1>
        <img className="absolute right-1 bottom-0" src={authVino}/>
        <div className="space-y-4 z-30 w-[316px]">
            <div className="space-y-2">
                <CavistaInput onChanged={onLoginChanged} value={state.login} text={"Логин"}/>
                <div className="w-full relative">
                    <input
                        type={state.isPasswordVisible ? "text" : "password"}
                        className="w-full bg-[#191919] p-4 rounded-2xl text-white outline-gray-400 outline-0"
                        placeholder={"Пароль"}
                        value={state.password}
                        onChange={(field) => onPasswordChanged(field.target.value)}
                    />
                    <div
                        onClick={onPasswordVisibilityClick}
                        className="absolute right-3 bottom-4 cursor-pointer">
                        {state.isPasswordVisible && <RemoveRedEyeIcon color={"error"}/>}
                        {!state.isPasswordVisible && <VisibilityOffIcon color={"error"}/>}
                    </div>
                </div>
            </div>
            <button
                className="rounded-2xl bg-[#6C1414] py-4 px-6 text-center
            align-middle font-sans text-xs font-bold uppercase text-white shadow-md
            shadow-gray-900/10 w-full hover:shadow-lg hover:shadow-gray-900/20"
                type="button"
                onClick={onLoginClicked}
            >
                Войти
            </button>
            <div className={error === "" ? "text-black" : "text-red-600"}>
                <h2 className="text-base text-center">{error}</h2>
            </div>
        </div>
    </div>)
}