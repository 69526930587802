import loading from "../ui/lottie/loading.json";
import IcPlaceholder from "../assets/placeholder.png"

export const defaultLoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export function getAvatar(imageUrl: string | null | undefined) {
    if (imageUrl) {
        if (imageUrl !== "") {
            return imageUrl
        } else {
            return IcPlaceholder
        }
    } else {
        return IcPlaceholder
    }
}

export function isString(value: any) {
    return typeof value === 'string' || value instanceof String;
}

export function isNumber(value: any) {
    try {
        return !isNaN(Number(value))
    } catch (e) {
        return false
    }
}