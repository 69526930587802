import {useLocation, useNavigate} from "react-router-dom";
import logo from "../../assets/logo.svg"
import {CaveCavistaRoutes} from "../../routing/CaveCavistaRoutes";
import {ProfileMenuWidget} from "../../ui/ProfileMenuWidget";
import {useSelector} from "react-redux";
import {RootState} from "../../domain/store";

export function CavistaHeader() {

    const locate = useLocation()
    const navigate = useNavigate()

    const authReducer = useSelector((state: RootState) => state.auth)
    const isAdmin = authReducer.role === "admin"

    const menuStyle = "block mt-4 lg:inline-block lg:mt-0 text-white hover:text-[#6C1414] text-base"
    const menuStyleActive = "block mt-4 lg:inline-block lg:mt-0 text-[#6C1414] hover:text-[#6C1414] text-base"

    const isSettings = locate.pathname.includes(CaveCavistaRoutes.settings)
    const isUsers = locate.pathname.includes(CaveCavistaRoutes.users)
    const isAuction = locate.pathname.includes(CaveCavistaRoutes.auction)
    const isProducts = locate.pathname.includes(CaveCavistaRoutes.products)
    const isSells = locate.pathname.includes(CaveCavistaRoutes.sells)

    return <>
        <div className="hidden lg:flex items-center justify-between bg-black h-20">
            <div
                onClick={() => navigate(CaveCavistaRoutes.auction)}
                className="flex items-center flex-shrink-0 cursor-pointer space-x-2 text-white mr-6 ml-4">
                <img src={logo} width="24px" height="24px"/>
                <h1 className="font-semibold text-xl tracking-tight text-[#6C1414]">Cave Cavista</h1>
            </div>
            <div className="w-full flex lg:items-center h-full">
                <div className="flex w-full items-center text-sm h-full space-x-2">
                    <div
                        onClick={() => navigate(CaveCavistaRoutes.auction)}
                        className="h-full relative flex items-center cursor-pointer">
                        <div className={isAuction ? menuStyleActive : menuStyle}>
                            Аукцион
                        </div>
                        <div
                            className={`absolute bottom-1 z-20 bg-[#6C1414] w-full h-1 ${isAuction ? 'block' : 'hidden'}`}/>
                    </div>
                    <div
                        onClick={() => navigate(CaveCavistaRoutes.products)}
                        className="h-full relative flex items-center cursor-pointer">
                        <div
                            className={isProducts ? menuStyleActive : menuStyle}>
                            Лоты
                        </div>
                        <div
                            className={`absolute bottom-1 z-20 bg-[#6C1414] w-full h-1 ${isProducts ? 'block' : 'hidden'}`}/>
                    </div>
                    {isAdmin && <div
                        onClick={() => navigate(CaveCavistaRoutes.users)}
                        className="h-full relative flex items-center cursor-pointer">
                        <div
                            className={isUsers ? menuStyleActive : menuStyle}>
                            Пользователи
                        </div>
                        <div
                            className={`absolute bottom-1 z-20 bg-[#6C1414] w-full h-1 ${isUsers ? 'block' : 'hidden'}`}/>
                    </div>}
                    {isAdmin && <div
                        onClick={() => navigate(CaveCavistaRoutes.sells)}
                        className="h-full relative flex items-center cursor-pointer">
                        <div
                            className={isSells ? menuStyleActive : menuStyle}>
                            Продажи
                        </div>
                        <div
                            className={`absolute bottom-1 z-20 bg-[#6C1414] w-full h-1 ${isSells ? 'block' : 'hidden'}`}/>
                    </div>}
                    {isAdmin && <div
                        onClick={() => navigate(CaveCavistaRoutes.settings)}
                        className="h-full relative flex items-center cursor-pointer">
                        <div
                            className={isSettings ? menuStyleActive : menuStyle}>
                            Настройки
                        </div>
                        <div
                            className={`absolute bottom-1 z-20 bg-[#6C1414] w-full h-1 ${isSettings ? 'block' : 'hidden'}`}/>
                    </div>}
                </div>
                <div className="flex items-center justify-center space-x-2">
                    <ProfileMenuWidget/>
                </div>
            </div>
        </div>
        <div className="w-full h-0.5 bg-[#191919] absolute bottom-1"/>
    </>
}