import {ItemUi} from "./ItemUi";

export interface GoodItemViewProps {
    item: ItemUi,
    onClick: (item: ItemUi) => void
}

export function ProductItemView({item, onClick}: GoodItemViewProps) {
    return (<div
        className={`cursor-pointer rounded-xl p-4 space-y-2 w-full bg-[#320909]`}
        onClick={() => onClick(item)}>
        <div className="rounded-md">
            <img
                src={item.imageUrl}
                className="m-auto h-[150px] w-[150px] object-contain aspect-auto"/>
        </div>
        <h1 className="text-xl text-center">{item.name}</h1>
        <div className="text-2xl text-white text-center">
            {item.showSoldPrice ? item.soldPrice : item.price}€ {item.showSoldPrice ? `+ ${(item.soldPrice * 0.05).toFixed(1)}€` : ""}
        </div>
        <div className="flex items-center justify-center space-x-4 text-white">
            <ul className="list-disc marker:text-[#6C1414]">
                {item.country !== "" && <li>{item.country}</li>}
                {item.year !== "" && <li>{item.year}</li>}
            </ul>
        </div>
        {item.user !== null && <p className="text-white text-2xl text-center">Забронирован</p>}
    </div>)
}