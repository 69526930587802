export enum CaveCavistaRoutes {
    auth = '/auth',
    users = '/users',
    createUser = 'create/user',
    createItem = 'create/item',
    auction = '/auction',
    purchased = '/purchased',
    sells = '/sells',
    settings = '/settings',
    search = '/search',
    products = '/products',
    profile = '/profile',
    nonAuthPage = '/nonAuthPage',
}