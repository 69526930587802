import useViewModel from "./AuctionViewModel"
import React from "react";
import {FullProductComponent} from "./components/FullProductComponent";
import {BottomProductsComponent} from "./components/BottomProductsComponent";
import {useLottie} from "lottie-react";
import {defaultLoadingOptions} from "../../ui/CommonItems";
import {AuctionState} from "./models/AuctionState";
import {AuctionIdleComponent} from "./components/AuctionIdleComponent";
import {ProductInfoComponent} from "./components/ProductInfoComponent";
import {AuctionPreparingComponent} from "./components/AuctionPreparingComponent";
import {UserWinProductComponent} from "./components/UserWinProductComponent";
import {AuctionFinishedComponent} from "./components/AuctionFinishedComponent";
import {AuctionPausedComponent} from "./components/AuctionPausedComponent";
import {SingleItemDialog} from "./item/SingleItemDialog";
import {NextProductComponent} from "./components/NextProductComponent";
import {MobileFullProductComponent} from "./components/MobileFullProductComponent";
import {AuctionHeader} from "./AuctionHeader";
import {ActiveUsersComponent} from "./components/ActiveUsersComponent";

export function AuctionPage() {

    const {
        state,
        isAdmin,
        price,
        users,
        onItemClicked,
        onMakeBetClicked,
        onShowUsersClick,
        onHandleVisibility,
        onCloseDialogClick,
        onReloadPageClicked,
        onInputPriceChanged,
        onShowFinishProducts,
        onStartAuctionClicked,
        onShowProductsClicked,
        onResumeAuctionClicked,
        onFinishAuctionClicked,
        onPrimaryButtonAuctionClicked,
    } = useViewModel()

    const {View} = useLottie(defaultLoadingOptions)
    return (<div className="bg-black overflow-y-auto">
        <AuctionHeader
            size={state.data.productsSize}
            timer={state.data.currentTime}
            state={state.data.auctionState}
            item={state.data.currentItem}
        />
        {!state.isLoading && state.error == null && <>
            {(state.data.auctionState === AuctionState.STARTED ||
                    state.data.auctionState === AuctionState.PAUSE ||
                    state.data.auctionState === AuctionState.USER_WIN) &&
                <div className="relative lg:h-[calc(100vh-90px)] lg:pt-4 pt-0">
                    <div className="flex">
                        <div className="lg:w-1/2 w-full">
                            <div
                                className={`lg:flex justify-between border-2 border-[#191919B2] rounded-md 
                        ${isAdmin ? 'lg:h-[calc(100vh-350px)] h-fit' : 'lg:h-[calc(100vh-290px)] h-fit'}`}>
                                <div className="lg:hidden block relative mt-4">
                                    <MobileFullProductComponent product={state.data.currentItem}/>
                                </div>
                                <ProductInfoComponent
                                    price={price}
                                    state={state.data}
                                    onMakeBetPressed={onMakeBetClicked}
                                    onInputPriceChanged={onInputPriceChanged}/>
                            </div>
                            <div className="h-fit relative w-full">
                                <BottomProductsComponent
                                    onItemClicked={onItemClicked}
                                    items={state.data.items}
                                    currentItem={state.data.currentItem}
                                />
                                <div className="w-full bg-[#191919] h-0.5 bottom-0 absolute"/>
                            </div>
                        </div>
                        <div className="lg:block hidden w-1/2">
                            <FullProductComponent
                                size={state.data.productsSize}
                                product={state.data.currentItem}
                            />
                        </div>
                    </div>

                    {isAdmin &&
                        <div className="lg:absolute lg:pb-0 pb-4 bottom-0 z-50 w-full mt-2 space-y-2">
                            <div className="flex space-x-2">
                                <button
                                    onClick={onFinishAuctionClicked}
                                    disabled={!state.data.isFinishButtonEnabled}
                                    className={`w-full rounded-xl text-white pt-2 pb-2 pl-4 pr-4 ${state.data.isFinishButtonEnabled ? "bg-[#6C1414]" : "bg-gray-800"}`}>Завершить
                                    торги
                                </button>
                                <button
                                    onClick={onPrimaryButtonAuctionClicked}
                                    disabled={!state.data.isPauseButtonEnabled}
                                    className={`w-full rounded-xl text-white pt-2 pb-2 pl-4 pr-4 ${state.data.isPauseButtonEnabled ? "bg-[#6C1414]" : "bg-gray-800"}`}>Пауза
                                </button>
                                <button
                                    onClick={onShowUsersClick}
                                    className={`w-full rounded-xl lg:block hidden text-white pt-2 pb-2 pl-4 pr-4 bg-[#6C1414]`}>Пользователи: {users.users.length}
                                </button>
                                <button
                                    onClick={onResumeAuctionClicked}
                                    disabled={!state.data.isResumeButtonEnabled}
                                    className={`w-full rounded-xl text-white pt-2 pb-2 pl-4 pr-4 ${state.data.isResumeButtonEnabled ? "bg-[#6C1414]" : "bg-gray-800"}`}>{state.data.startAuctionText}
                                </button>
                            </div>
                            <button
                                onClick={onShowUsersClick}
                                className={`w-full lg:hidden block rounded-xl text-white pt-2 pb-2 pl-4 pr-4 bg-[#6C1414]`}>Пользователи: {users.users.length}
                            </button>
                        </div>}
                    {state.data.isUserWinProductVisible && <UserWinProductComponent
                        productImage={state.data.currentItem.imageUrl}
                        price={price.currentPrice}
                        closeDialog={onCloseDialogClick}/>
                    }
                    {state.data.nextProductVisible && <NextProductComponent closeDialog={onCloseDialogClick}/>}
                    {state.data.isPauseDialogVisible &&
                        <AuctionPausedComponent onResumeAuctionClicked={onResumeAuctionClicked}/>}
                </div>}
            {state.data.auctionState === AuctionState.IDLE && <AuctionIdleComponent
                state={state}
                isAdmin={isAdmin}
                onShowProductsClicked={onShowProductsClicked}
                onStartAuctionClicked={onStartAuctionClicked}/>}
            {state.data.auctionState === AuctionState.PREPARING && <AuctionPreparingComponent
                delay={state.data.delay}/>}
            {state.data.auctionState === AuctionState.FINISH &&
                <AuctionFinishedComponent isAdmin={isAdmin} onShowProductsClicked={onShowFinishProducts}/>}
        </>}
        {state.isLoading && <div
            className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {View}
        </div>}
        {state.error !== null && <div
            className="absolute z-20 text-white space-y-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h2 className="text-white">Ошибка: {state.error}</h2>
            <button
                className="w-full bg-[#6C1414] rounded-md p-2 text-white active:opacity-100 focus:opacity-100"
                onClick={onReloadPageClicked}>Перезагрузить
            </button>
        </div>}
        {state.data.isFullItemDialogVisible.isVisible &&
            <SingleItemDialog
                product={state.data.isFullItemDialogVisible.item}
                index={state.data.isFullItemDialogVisible.index}
                isVisible={state.data.isFullItemDialogVisible.isVisible}
                handleVisibility={onHandleVisibility}/>}
        {users.isUsersVisible && <ActiveUsersComponent
            users={users.users}
            handleVisibility={onShowUsersClick}
            isVisible={users.isUsersVisible}/>}
    </div>)
}