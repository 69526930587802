import useViewModel from "./PurchasedViewModel"
import {useLottie} from "lottie-react";
import {defaultLoadingOptions} from "../../../ui/CommonItems";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {PurchasedProductsTable} from "./PurchasedProductsTable";

export function PurchasedPage() {
    const {state, onReloadPageClick, onBackClicked} = useViewModel()
    const {View} = useLottie(defaultLoadingOptions);
    return (<div className="bg-black pt-4">
        <div className="flex ml-4 mr-4 justify-between relative">
            <div className="flex items-center space-x-4 lg:ml-2 ml-0 lg:mr-4">
                <div onClick={onBackClicked}>
                    <ArrowBackIcon style={{color: 'white'}}/>
                </div>
            </div>
            <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl font-bold">Ваши покупки</h1>
            {!state.isLoading && state.error == null && <div>
                <div
                    className="text-white bg-[#6C1414] items-center flex rounded-xl pt-2 pb-2 pl-4 pr-4">
                    {state.price.toFixed(1)} €
                </div>
            </div>}
        </div>
        {(!state.isEmpty && !state.isLoading && state.error == null) && <PurchasedProductsTable models={state.items}/>}
        {state.isLoading && <div
            className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {View}
        </div>}
        {state.isEmpty && <div
            className="absolute z-20 text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#818181] uppercase text-xl">
            Вы ничего не выиграли
        </div>}
        {state.error !== null && <div
            className="absolute z-20 space-y-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h2 className="text-white">Ошибка: {state.error}</h2>
            <button
                className="w-full bg-[#6C1414] rounded-md p-2 text-white active:opacity-100 focus:opacity-100"
                onClick={onReloadPageClick}>Перезагрузить
            </button>
        </div>}
    </div>)
}