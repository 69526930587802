import CloseIcon from "@mui/icons-material/Close";

export interface UserWinProductComponentProps {
    price: string
    productImage: string
    closeDialog: () => void
}

export function UserWinProductComponent({productImage, price, closeDialog}: UserWinProductComponentProps) {
    return (<div className="absolute top-0 w-full h-full">
        <div className="absolute w-full h-full z-10 opacity-60 bg-black"/>
        <div
            className="absolute z-50 absolute top-1/4 lg:top-1/2 border-4 border-black left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#320909] w-[360px] h-fit rounded-lg">
            <div className="space-y-4 p-4 mt-4 text-center text-white">
                <h2 className="text-xl text-center">Лот продан за {price}€</h2>
                <img className="w-[200px] object-contain h-[200px] m-auto rounded-lg" src={productImage}/>
                <p>Это окно закроется само через 5 секунд и начнется следующий лот</p>
            </div>
            <div
                onClick={closeDialog}
                className="absolute top-2 right-2">
                <CloseIcon/>
            </div>
        </div>
    </div>)
}