import {useCallback, useEffect, useState} from "react";
import {
    useLoadSellsProductsMutation,
    useSearchProductsMutation
} from "../../data/products/ProductsApi";
import {ProductsResponse} from "../../data/products/responses/ProductsResponse";
import {ProductResponse} from "../../data/products/responses/ProductResponse";
import {ProductUiConverter} from "../products/converter/ProductUiConverter";
import {debounce} from "@mui/material";
import {ItemUi} from "../products/main/ItemUi";
import {initSellsState, SellsItemUi, SellsState} from "./SellsState";

export default function SellsViewModel() {

    const [state, setState] = useState<SellsState>(initSellsState())
    const [price, setPrice] = useState(0)
    const [priceWith, setWithPrice] = useState(0)
    const [search, setSearch] = useState<string>("")

    const [load] = useLoadSellsProductsMutation()
    const [searchMutation] = useSearchProductsMutation()

    useEffect(() => {
        fetchData()
    }, [])

    function fetchData() {

        async function loadGoods() {
            return await load().unwrap()
        }

        loadGoods().then((response: ProductsResponse) => {

            const uiItems = response.items.map((model: ProductResponse) => ProductUiConverter(model, true))
            const sellsUiItems = convertItems(uiItems)

            setPrice(uiItems
                .map((item: ItemUi) => Number(item.soldPrice))
                .reduce((sum, current) => sum + current, 0))

            setWithPrice(
                (uiItems
                    .map((item: ItemUi) => Number(item.soldPrice))
                    .reduce((sum, current) => sum + (current + current * 0.05), 0))
            )

            setState({
                ...state,
                isLoading: false,
                items: sellsUiItems,
                isEmpty: sellsUiItems.length === 0,
                error: null
            })
        }).catch((e) => {
            setState({
                ...state,
                isLoading: false,
                error: e.status
            })
        })
    }

    const onReloadPageClick = () => {
        fetchData()
    }

    useEffect(() => {
        if (search !== "") {
            verify(search);
        } else {
            fetchData()
        }
    }, [search]);

    function convertItems(items: ItemUi[]) {
        const sellsUiItems: SellsItemUi[] = [];
        for (const item of items) {
            if (item.user) {
                if (!sellsUiItems[item.user.id]) sellsUiItems[item.user.id] = {
                    user: item.user,
                    products: []
                };
                sellsUiItems[item.user.id] = {
                    user: item.user,
                    products: items.filter((product) => product.user?.id === item.user?.id)
                };
            }
        }
        return sellsUiItems
    }

    const verify = useCallback(debounce(search => {
        searchProducts(search)
            .then((products: ProductsResponse) => {
                const uiItems = products.items.map((model: ProductResponse) => ProductUiConverter(model))
                const sellsUiItems = convertItems(uiItems)
                setState({
                    ...state,
                    items: sellsUiItems,
                    isLoading: false,
                    isEmpty: uiItems.length === 0,
                    error: null
                })
            })
            .catch((e) => {
                console.log(e)
                setState({
                    ...state,
                    items: [],
                    isLoading: false,
                    error: e.message
                })
            })
    }, 0), []);

    async function searchProducts(search: string) {
        return await searchMutation({query: search, onlySells: true}).unwrap()
    }


    return {
        state,
        search,
        price,
        priceWith,
        setSearch,
        onReloadPageClick
    }

}