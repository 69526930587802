

export interface SingleItemState {
    imageUrl: string
    isVisible : boolean
}


export function initSingleItemState() : SingleItemState {
    return {
        imageUrl: "",
        isVisible: false
    }
}