import React from 'react';
import {MainRoutes} from "./routing/MainRoutes";
import {useSelector} from "react-redux";
import {RootState} from "./domain/store";
import {useLocation} from "react-router-dom";
import {CaveCavistaRoutes} from "./routing/CaveCavistaRoutes";
import topWave from "./assets/top_wave.png"
import bottomWave from "./assets/bottom_wave.png"
import {CavistaHeader} from "./features/main/CavistaHeader";
import {MobileCavistaHeader} from "./features/main/MobileCavistaHeader";

function App() {

    const locate = useLocation()

    const authReducer = useSelector((state: RootState) => state.auth)

    const isCreateUserPage = locate.pathname.includes(CaveCavistaRoutes.createUser)
    const isUserPage = locate.pathname.includes(CaveCavistaRoutes.users + "/")
    const isProductPage = locate.pathname.includes(CaveCavistaRoutes.products + "/")
    const isCreateItemPage = locate.pathname.includes(CaveCavistaRoutes.createItem)
    const isAuth = locate.pathname.includes(CaveCavistaRoutes.auth)
    const isPurchased = locate.pathname.includes(CaveCavistaRoutes.purchased)
    const isAuction = locate.pathname.includes(CaveCavistaRoutes.auction)

    const isHeaderVisible = !isCreateUserPage && !isCreateItemPage && !isUserPage && !isProductPage && !isPurchased && !isAuth
    const isMobileVisible = !isAuction

    return (
        <div
            className="relative w-screen scrollbar-none bg-gradient-to-b from-[#050505] to-[#6C1414] flex overscroll-none">
            <img className="w-full absolute bottom-0 -z-0" src={topWave}/>
            <img className="w-full absolute bottom-0 -z-0" src={bottomWave}/>
            <div className="flex w-full">
                {!isAuth && <div className="w-[100px] hidden lg:block"/>}
                <div className="lg:max-w-[calc(100vw-100px)] lg:w-[calc(100vw-100px)] w-full">
                    {(authReducer.token !== "" && isHeaderVisible) && <header className="z-40 sticky top-0">
                        <CavistaHeader/>
                        {isMobileVisible && <MobileCavistaHeader/>}
                    </header>}
                    <div
                        className={`relative bg-black ${isHeaderVisible ? "h-[calc(100vh)] lg:h-[calc(100vh-80px)]" : "h-[calc(100vh)]"}`}>
                        <MainRoutes/>
                    </div>
                </div>
                {!isAuth && <div className="w-[100px] hidden lg:block"/>}
            </div>
        </div>
    );
}

export default App;
