import React, {createContext, ReactNode, useContext, useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import {Alert} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";

const AlertContext = createContext<any>(undefined);

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};

export interface IProps {
    children?: ReactNode
}

export const AlertProvider = ({children}: IProps) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState<AlertColor>('info')
    const showAlert = (newMessage: string, type: AlertColor) => {
        setMessage(newMessage);
        setSeverity(type)
        setOpen(true);
    };

    const hideAlert = () => {
        setOpen(false);
    };

    return (
        <AlertContext.Provider value={showAlert}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={hideAlert}
                message={message}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert
                    severity={severity}
                    variant="filled"
                    className="text-xl"
                    sx={{width: '100%', fontSize: 20}}
                >
                    {message}
                </Alert>
            </Snackbar>
        </AlertContext.Provider>
    );
};