import {useNavigate} from "react-router-dom";
import {CaveCavistaRoutes} from "../../../routing/CaveCavistaRoutes";
import {ItemUi} from "../../products/main/ItemUi";
import {ProductItemView} from "../../products/main/ProductItemView";

export interface SortableTableProps {
    models: ItemUi[]
}

export function PurchasedProductsTable({models}: SortableTableProps) {

    const navigate = useNavigate()

    const onClick = (item: ItemUi) => {
        navigate(CaveCavistaRoutes.products + "/" + item.id)
    }

    return (
        <div
            className="pl-4 pr-4 lg:max-h-[calc(100vh-60px)] h-full pt-4 w-full overflow-y-scroll scrollbar-none grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2">
            {models.map((model) => {
                    return (<ProductItemView key={model.id} item={model} onClick={onClick}/>);
                }
            )}
        </div>
    );
}