import {ItemUi} from "./ItemUi";
import {useNavigate} from "react-router-dom";
import {ProductItemView} from "./ProductItemView";
import {CaveCavistaRoutes} from "../../../routing/CaveCavistaRoutes";
import React, {useEffect, useRef} from "react";
import {updateProductsScrollPosition} from "../../../data/products/ProductsSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../domain/store";
import {generateRandomString} from "../../../domain/utils/Utils";

export interface SortableTableProps {
    models: ItemUi[]
    loadMoreButton: (() => void) | null
}

export function ProductsTable({models, loadMoreButton}: SortableTableProps) {

    const productsReducer = useSelector((state: RootState) => state.products)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const reference = useRef<HTMLImageElement>(null)

    const onClick = (item: ItemUi) => {
        navigate(CaveCavistaRoutes.products + "/" + item.id)
    }

    const handleScroll = (event: any) => {
        dispatch(updateProductsScrollPosition(event.currentTarget.scrollTop))
    };

    useEffect(() => {
        if (reference) {
            reference.current?.scrollTo({top: productsReducer.pageScrollPosition})
        }
    }, [reference])

    return (
        <div
            id="productsTable"
            onScroll={handleScroll}
            ref={reference}
            className="lg:max-h-[calc(100vh-185px)] max-h-[calc(100vh-120px)] pl-4 pr-4 pt-2 w-full overflow-y-scroll scrollbar-none grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2">
            {models.map((model, index) => {
                    return (<ProductItemView key={generateRandomString(15)} item={model} onClick={onClick}/>);
                }
            )}
            {loadMoreButton != null && <button
                className="w-[200px] left-1/2 transform -translate-x-1/2 bg-[#6C1414] absolute bottom-6 rounded-md p-2 text-white active:opacity-100 focus:opacity-100"
                onClick={loadMoreButton}>Загрузить еще
            </button>}
        </div>
    );
}