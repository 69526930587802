import React from "react";
import {AuctionData, PriceData} from "../AuctionState";

export interface ProductInfoComponentProps {
    state: AuctionData
    price: PriceData,
    onInputPriceChanged: (price: number) => void
    onMakeBetPressed: () => void
}

export function ProductInfoComponent({state, price, onMakeBetPressed, onInputPriceChanged}: ProductInfoComponentProps) {
    return <div className="relative lg:h-full h-fit w-full">
        <div className="lg:block hidden lg:absolute text-white text-center">
            Примечание! Если у вас не идет таймер ставки, не ставится ставка или не прогрузились фотографии, тогда
            попробуйте обновить страницу стайта.
        </div>
        <div
            className="lg:block hidden lg:absolute relative w-full text-center font-bold lg:top-1/3 lg:left-1/2 transform space-y-2 lg:-translate-x-1/2 lg:-translate-y-1/2">
            <h1 className="text-3xl">Таймер ставки</h1>
            <h1 className="text-3xl">{state.currentTime}</h1>
        </div>
        <div
            className="lg:absolute relative bottom-4 lg:mt-0 mt-4 lg:flex justify-center lg:space-x-16 space-x-0 lg:pt-0 pt-4 w-full">
            <div
                className="flex lg:flex-col lg:space-y-6 space-x-2 lg:space-x-0 lg:justify-normal justify-center items-center">
                <h2 className="text-[#818181]">Текущая цена</h2>
                <div className="flex lg:flex-col items-center space-x-2 lg:space-x-0 lg:space-y-4">
                    <h1 className="text-[#6C1414] lg:text-5xl text-2xl font-bold">{price.currentPrice}€</h1>
                    <div className="lg:block hidden text-[#818181] space-y-1">
                        <p className=" text-center">Сервисный сбор 5%</p>
                        <p className="text-[#818181] text-center">Курс: 1€ = {state.rate}руб</p>
                    </div>
                </div>
            </div>
            <div className="lg:space-y-2 space-y-0 lg:mt-0 mt-4">
                <h2 className="lg:block hidden text-[#818181] lg:text-start text-center">Сделать ставку</h2>
                <div className="flex flex-col lg:space-y-4 space-y-2">
                    <input
                        value={price.inputPrice}
                        onChange={(item) => onInputPriceChanged(Number(item.target.value))}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        className="border-2 text-white border-white outline-none p-4 rounded-md bg-black"
                        placeholder="20"
                    />
                    <p className="lg:hidden block text-center text-[#818181]">Сервисный сбор 5%</p>
                    <div className="w-full">
                        <button
                            onClick={() => onMakeBetPressed()}
                            className="rounded-xl w-full bg-[#6C1414] text-white pt-2 pb-2 pl-4 pr-4">Сделать
                            ставку
                        </button>
                        <p className="text-[#818181] text-center">шаг ставки {state.betStep}€</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}