

export enum AuctionWebSocketTypes {
    userJoined = 'JOIN',
    started = 'STARTED',
    preparing = 'PREPARING',
    paused = 'PAUSE',
    users = 'USERS',
    changeBet = 'CHANGE_BET',
    changeBetError = 'CHANGE_BET_ERROR',
    finish = 'FINISH',
    userWin = "USER_WIN",
    usersCount = 'USERS_COUNT',
    changeProduct = 'CHANGE_PRODUCT',
    logout = 'LOGOUT',
    closeDialog = "CLOSE_DIALOG",
    userDisconnected = 'DISCONNECT',
}