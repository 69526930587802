import {useNavigate} from "react-router-dom";
import {CaveCavistaRoutes} from "../../routing/CaveCavistaRoutes";
import {Button} from "@mui/material";

export function NotExistsPage() {
    const navigate = useNavigate()
    return <div className="m-auto">
        <div className="space-y-4">
            <h1 className="text-center text-white text-2xl">Такой страницы не существует</h1>
            <Button
                className="m-auth bg-[#FD8C4C] w-full"
                onClick={() => navigate(CaveCavistaRoutes.auth, {replace: true})}
            />
        </div>
    </div>
}