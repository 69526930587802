import React from "react";

export interface AuctionPausedComponentProps {
    onResumeAuctionClicked: () => void
}

export function AuctionPausedComponent({onResumeAuctionClicked}: AuctionPausedComponentProps) {
    return (<div className="absolute top-0 w-full h-full">
        <div className="absolute w-full h-full z-10 opacity-60 bg-black"/>
        <div
            className="absolute z-50 absolute top-1/4 lg:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#320909] w-[360px] h-fit rounded-lg">
            <div className="m-auto space-y-4 p-4 text-center text-white">
                <h2 className="text-2xl">Аукцион на паузе!</h2>
                <p>Подождите, пока админ снова возобновит торги</p>
            </div>
            <button
                onClick={onResumeAuctionClicked}
                className={`lg:hidden block w-full rounded-xl text-white pt-2 pb-2 pl-4 pr-4 bg-[#6C1414]`}>Возобновить
            </button>
        </div>
    </div>)
}