import React, {useRef} from "react";
import {SellsItemUi} from "./SellsState";
import {DownloadTableExcel} from 'react-export-table-to-excel';
import {ItemUi} from "../products/main/ItemUi";

export interface DownloadProps {
    data: SellsItemUi[]
}

export function Download({data}: DownloadProps) {
    const tableRef = useRef(null);

    const sellsUiItems: ItemUi[] = [];
    data.forEach((item) => {
        item.products.forEach((product) => {
            sellsUiItems.push(product)
        })
    })

    return (<div>
            <DownloadTableExcel
                filename="Таблица продаж"
                sheet="users"
                currentTableRef={tableRef.current}>
                <div
                    className="cursor-pointer text-white bg-[#6C1414] items-center flex rounded-xl pt-2 pb-2 pl-4 pr-4">Экспортировать
                    в Excel
                </div>

            </DownloadTableExcel>

            <table className="hidden" ref={tableRef}>
                <tr>
                    <th>Имя пользователя</th>
                    <th>Контакты</th>
                    <th>Адрес</th>
                    <th>Название лота</th>
                    <th>Цена продажи</th>
                    <th>Цена с коммисей</th>
                    <th>Год</th>
                    <th>Страна</th>
                    <th>Описание</th>
                </tr>
                {sellsUiItems.map((item) => {
                    return <tr>
                        <td>{item?.user?.fullName ?? ""}</td>
                        <td>"{item?.user?.contacts ?? ""}"</td>
                        <td>{item?.user?.address ?? ""}</td>
                        <td>{item.name}</td>
                        <td>{item.soldPrice}</td>
                        <td>{item.soldPrice + item.soldPrice * 0.05}</td>
                        <td>{item.year}</td>
                        <td>{item.country}</td>
                        <td>{item.description}</td>
                    </tr>
                })}
            </table>
        </div>
    );
}