

export interface AuthorizationState {
    login: string
    password: string
    isPasswordVisible: boolean
}

export function initAuthState(): AuthorizationState {
    return {
        login: "",
        password: "",
        isPasswordVisible: false
    }
}