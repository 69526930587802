import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ServerUrl} from "../../constants/Constants";
import {AuthResponse} from "./responses/AuthResponse";
import {AuthRequest} from "./requests/AuthRequet";
import {CreateUserRequest} from "./requests/CreateUserRequest";
import {SuccessResponse} from "../common/SuccessResponse";
import {DeleteUserRequest} from "./requests/DeleteUserRequest";
import {UserResponse} from "./responses/UserResponse";
import {UpdateUserRequest} from "./requests/UpdateUserRequest";
import {GetUserRequest} from "./requests/GetUserRequest";
import {UsersResponse} from "./responses/UsersResponse";
import {RootState} from "../../domain/store";
import {SearchUsersRequest} from "./requests/SearchUsersRequest";

export const AuthApi = createApi({
    reducerPath: 'cavista/users/authorization', baseQuery: fetchBaseQuery({
        baseUrl: `${ServerUrl}/users`, prepareHeaders: (headers, {getState}) => {
            const slice = (getState() as RootState).auth
            if (slice.token) {
                headers.set('token', `${slice.token}`)
            }
            return headers
        }
    }), endpoints: build => ({
        login: build.mutation<AuthResponse, AuthRequest>({
            query: (body) => ({
                url: `/login`, method: `POST`, body
            }),
            transformResponse: (response: AuthResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        loadUsers: build.mutation<UsersResponse, void>({
            query: (body) => ({
                url: `/all`, method: `GET`
            }),
            transformResponse: (response: UsersResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        searchUsers: build.mutation<UsersResponse, SearchUsersRequest>({
            query: (body) => ({
                url: `/search`, method: `GET`, params: {
                    query: body.query
                }
            }),
            transformResponse: (response: UsersResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        loadUser: build.mutation<UserResponse, GetUserRequest>({
            query: (body) => ({
                url: `/single`, method: `GET`, params: {
                    userId: body.userId
                }
            }),
            transformResponse: (response: UserResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        addUser: build.mutation<UserResponse, CreateUserRequest>({
            query: (body) => ({
                url: `/add`, method: `POST`, body
            }),
            transformResponse: (response: UserResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        updateUser: build.mutation<SuccessResponse, UpdateUserRequest>({
            query: (body) => ({
                url: `/update`, method: `POST`, body
            }),
            transformResponse: (response: SuccessResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        deleteUser: build.mutation<SuccessResponse, DeleteUserRequest>({
            query: (body) => ({
                url: `/delete`, method: `DELETE`, params: {
                    userId: body.userId
                }
            }),
            transformResponse: (response: SuccessResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
    })
})

export const {
    useLoginMutation,
    useAddUserMutation,
    useLoadUserMutation,
    useLoadUsersMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useSearchUsersMutation,
} = AuthApi