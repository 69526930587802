export interface UsersState {
    users: UserUi[]
    isLoading: boolean
    isEmpty: boolean
    error: string | null
}

export interface UserUi {
    id: number
    fullName: string
    login: string
    address: string
    password: string
    contacts: string
    buttonType: ButtonType.EDIT
}

export enum ButtonType {
    EDIT,
    DELETE,
    SAVE
}

export function initUsersState(): UsersState {
    return {
        isLoading: true,
        isEmpty: false,
        error: null,
        users: []
    }
}