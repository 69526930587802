import {createSlice} from "@reduxjs/toolkit";
import {ProductResponse} from "./responses/ProductResponse";
import {getAvatar} from "../../ui/CommonItems";

const initialState: ProductsData = {
    items: [],
    offset: 0,
    pageScrollPosition: 0,
    sort: null
}

export interface ProductsData {
    items: ProductResponse[]
    offset: number
    sort: boolean | null
    pageScrollPosition: number
}

export const productsSlice = createSlice({
    name: "products", initialState: initialState, reducers: {
        updateProducts: (state, action) => {
            state.items = state.items.concat(action.payload.items)
            state.offset = action.payload.offset
        },
        clearProducts: (state) => {
            state.items = []
            state.offset = 0
            state.pageScrollPosition = 0
        },
        updateSort : (state, action) => {
            state.sort = action.payload
        },
        updateProductsScrollPosition: (state, action) => {
            state.pageScrollPosition = action.payload
        },
        deleteProductSlice: (state, action) => {
            state.items = state.items.filter((item) => item.id !== action.payload)
        },
        updateProductSlice: (state, action) => {
            state.items = state.items.map((item) => {
                if (item.id === action.payload.id) {
                    item.price = action.payload.price
                    item.description = action.payload.description
                    item.country = action.payload.country
                    item.avatarUrl = action.payload.avatarUrl
                    item.type = action.payload.type
                    item.name = action.payload.name
                }
                return item
            })
        },
        addProduct: (state, action) => {
            state.items = state.items.concat(action.payload as ProductResponse)
        },
        deleteProductAvatarSlice: (state, action) => {
            console.log(action.payload)
            state.items = state.items.map((item) => {
                if (item.id === action.payload) {
                    item.avatarUrl = getAvatar(null)
                }
                return item
            })
        }
    },
});

export const {
    updateProducts,
    clearProducts,
    updateSort,
    updateProductsScrollPosition,
    updateProductSlice,
    deleteProductSlice,
    deleteProductAvatarSlice,
    addProduct
} = productsSlice.actions

export const productsReducer = productsSlice.reducer