import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ServerUrl} from "../../constants/Constants";
import {RootState} from "../../domain/store";
import {ConfigResponse} from "./responses/ConfigResponse";
import {AuctionRequest} from "./requests/AuctionRequest";
import {SuccessResponse} from "../common/SuccessResponse";
import {UpdateConfigRequest} from "./requests/UpdateConfigRequest";

export const ConfigApi = createApi({
    reducerPath: 'cavista/config', baseQuery: fetchBaseQuery({
        baseUrl: `${ServerUrl}/config`, prepareHeaders: (headers, {getState}) => {
            const slice = (getState() as RootState).auth
            if (slice.token) {
                headers.set('token', `${slice.token}`)
            }
            return headers
        }
    }), endpoints: build => ({
        loadConfig: build.mutation<ConfigResponse, void>({
            query: (body) => ({
                url: `/data`, method: `GET`, body
            }),
            transformResponse: (response: ConfigResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        updateConfig: build.mutation<SuccessResponse, UpdateConfigRequest>({
            query: (body) => ({
                url: `/update`, method: `POST`, body
            }),
            transformResponse: (response: SuccessResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        deleteAllUsers: build.mutation<SuccessResponse, void>({
            query: (body) => ({
                url: `/deleteAllUsers`, method: `POST`, body
            }),
            transformResponse: (response: SuccessResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
        handleAuction: build.mutation<SuccessResponse, AuctionRequest>({
            query: (body) => ({
                url: `/auction`, method: `POST`, body
            }),
            transformResponse: (response: SuccessResponse) => response,
            transformErrorResponse(baseQueryReturnValue: unknown, meta: unknown, arg: unknown): string {
                console.log(`${baseQueryReturnValue}`)
                return baseQueryReturnValue as string
            }
        }),
    })
})

export const {
    useLoadConfigMutation,
    useUpdateConfigMutation,
    useHandleAuctionMutation,
    useDeleteAllUsersMutation
} = ConfigApi

