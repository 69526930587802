import React from "react";
import {SingleItemImageUi} from "./SingleItemUi";
import CloseIcon from "@mui/icons-material/Close";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export interface SingleImageProps {
    image: SingleItemImageUi
    isAdmin: boolean
    onDeleteAvatarClicked: (name: string, imageId: number) => void
    uploadImageResult: (viewId: string, data: any) => void
}

export function SingleImageView({
                                    image,
                                    isAdmin,
                                    onDeleteAvatarClicked,
                                    uploadImageResult
                                }: SingleImageProps) {
    return <div
        className="relative flex flex-col items-center">
        {image.source !== null && <Zoom>
            <img
                src={image.source}
                id={image.viewId}
                className="w-[75px] h-[75px] object-contain border-2 border-white rounded-lg"
            />
        </Zoom>}
        {image?.isRealFile === true && isAdmin && <div
            onClick={() => onDeleteAvatarClicked(image.viewId, image.imageId)}
            className="absolute bg-white -top-2 right-1 rounded-full">
            <CloseIcon/>
        </div>}
        {isAdmin && <div>
            <label
                htmlFor={`image${image?.viewId}`}
                className="text-center text-white w-full pl-4 pb-2 pr-4 flex
                            justify-center cursor-pointer underline rounded-md">Добавить</label>
            <input
                type="file"
                onChange={(value) => uploadImageResult(image?.viewId ?? "", value)}
                id={`image${image?.viewId}`}
                name={`image${image?.viewId}`}
                className="hidden"
                readOnly={true}
                accept=".jpg, .jpeg, .png, .webp, .heic"/>
        </div>}
    </div>
}