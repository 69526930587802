import {ItemUi} from "../products/main/ItemUi";


export interface AuctionScreenState {
    data: AuctionData
    isLoading: boolean
    error: string | null
}

export interface DialogData {
    isVisible: boolean
    index: number
    item: ItemUi
}

export interface PriceData {
    currentPrice: string
    inputPrice: number
}

export function initPriceData(): PriceData {
    return {
        currentPrice: "0",
        inputPrice: 0
    }
}


export interface ConnectedUsers {
    users: ConnectedUser[]
    isUsersVisible: boolean
}

export interface ConnectedUser {
    username: string
    userId: number
    userSid: string
}

export interface AuctionData {
    auctionState: string
    delay: string
    isStartButtonEnabled: boolean
    betStep: number
    rate: number
    currentTime: string
    startTime: string
    startDate: string
    currentItemIndex: number
    isUserWinProductVisible: boolean
    isPauseDialogVisible: boolean
    isResumeButtonEnabled: boolean
    isFullItemDialogVisible: DialogData
    nextProductVisible: boolean
    isFinishButtonEnabled: boolean
    isPauseButtonEnabled: boolean
    productsSize: number
    startAuctionText: string
    currentItem: ItemUi
    items: ItemUi[]
}

export function initProductUi(): ItemUi {
    return {
        id: 0,
        user: null,
        imageUrl: "",
        year: "1999",
        country: "Russia",
        isEnabled: true,
        index: 0,
        images: [""],
        priceNumber: 0,
        soldPrice: 0,
        description: "",
        showSoldPrice: false,
        name: "Вино",
        price: "2000"
    }
}

export function initAuctionState(): AuctionScreenState {
    return {
        isLoading: true,
        error: null,
        data: {
            auctionState: "",
            rate: 0,
            startDate: "",
            productsSize: 0,
            isStartButtonEnabled: false,
            delay: "",
            isFullItemDialogVisible: {
                isVisible: false,
                index: 0,
                item: initProductUi()
            },
            betStep: 0,
            startTime: "",
            isFinishButtonEnabled: false,
            nextProductVisible: false,
            isPauseDialogVisible: false,
            isPauseButtonEnabled: false,
            currentItemIndex: 1,
            isResumeButtonEnabled: false,
            isUserWinProductVisible: false,
            startAuctionText: "",
            currentItem: initProductUi(),
            currentTime: "10 секунд",
            items: [],
        }
    }
}