import {SingleItemUi} from "./SingleItemUi";
import {initImageStub} from "../converter/ProductSingleUiConverter";

export interface FullImageState {
    isVisible: boolean
    imageUrl: string
}

export interface SingleItemState {
    isLoading: boolean
    data: SingleItemUi
    isAdmin: boolean
    primaryButtonText: string
    fullImageState: FullImageState
    isPrimaryButtonEnabled: boolean
    title: string
    error: string | null
}

export function initSingleItemState(): SingleItemState {
    return {
        isLoading: true,
        title: "Создать лот",
        error: null,
        primaryButtonText: "Создать",
        isPrimaryButtonEnabled: true,
        isAdmin: false,
        fullImageState: {
            isVisible: false,
            imageUrl: ""
        },
        data: {
            soldPrice: null,
            priority: "0",
            avatarFile: initImageStub({imageId: 0, imageUrl: null}, "avatar"),
            id: null,
            images: [
                initImageStub({imageId: 0, imageUrl: null}, "view_1"),
                initImageStub({imageId: 0, imageUrl: null}, "view_2"),
                initImageStub({imageId: 0, imageUrl: null}, "view_3")
            ],
            user: null,
            name: "",
            type: "",
            description: "",
            isEnabled: false,
            price: "",
            year: "",
            country: ""
        }
    }
}