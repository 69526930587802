export interface CavistaInputProps {
    onChanged: (text: string) => void
    value: string
    type ?: string
    disabled ?: boolean
    title ?: string
    text: string
}

export function CavistaInput({onChanged, value, disabled, type, title, text}: CavistaInputProps) {
    return <div className="w-full">
        <h3 className="mb-1 text-white">{title}</h3>
        <input
            type={type}
            disabled={disabled}
            className="w-full bg-[#191919] p-4 rounded-2xl text-white outline-gray-400 outline-0"
            placeholder={text}
            value={value}
            onChange={(field) => onChanged(field.target.value)}
        />
    </div>
}