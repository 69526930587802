import {useEffect, useState} from "react";
import {ProductsResponse} from "../../../data/products/responses/ProductsResponse";
import {ProductResponse} from "../../../data/products/responses/ProductResponse";
import {useNavigate} from "react-router-dom";
import {useLoadUserProductsMutation} from "../../../data/products/ProductsApi";
import {ProductUiConverter} from "../../products/converter/ProductUiConverter";
import {ItemUi} from "../../products/main/ItemUi";
import {initPurchasedState, PurchasedState} from "./PurchasedState";


export default function PurchasedViewModel() {

    const [state, setState] = useState<PurchasedState>(initPurchasedState())

    const navigate = useNavigate()

    const [load] = useLoadUserProductsMutation()

    useEffect(() => {
        fetchData()
    }, [])

    function fetchData() {

        async function loadGoods() {
            return await load().unwrap()
        }

        loadGoods().then((goods: ProductsResponse) => {
            const uiItems = goods.items.map((model: ProductResponse) => ProductUiConverter(model, true))
            setState({
                ...state,
                items: uiItems,
                isLoading: false,
                price: uiItems
                    .map((item: ItemUi) => Number(item.soldPrice))
                    .reduce((sum, current) => sum + (current + current * 0.05), 0),
                isEmpty: uiItems.length === 0,
                error: null
            })
        }).catch((e) => {
            setState({
                ...state,
                isLoading: false,
                error: e.status
            })
        })
    }

    const onReloadPageClick = () => {
        fetchData()
    }

    const onBackClicked = () => {
        navigate(-1)
    }

    return {
        state,
        onBackClicked,
        onReloadPageClick,
    }

}