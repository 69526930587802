import {ItemUi} from "../../products/main/ItemUi";
import React from "react";
import {Dialog} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {SingleImageFullDialog} from "../../products/single/SingleImageFullDialog";
import useViewModel from "./SingleItemDialogViewModel"

export interface SingleItemDialogProps {
    product: ItemUi
    isVisible: boolean
    index: number
    handleVisibility: () => void
}

export function SingleItemDialog({isVisible, index, product, handleVisibility}: SingleItemDialogProps) {
    const {state, handleDialogVisibility, onShowFullImageClick} = useViewModel()
    return <Dialog
        open={isVisible}
        onClose={handleVisibility}>
        <div
            className="bg-[#320909] p-4 h-full relative text-white text-center">
            <div
                onClick={() => handleVisibility()}
                className="absolute right-2 top-2">
                <CloseIcon/>
            </div>
            <div className="flex justify-center items-center pt-8">
                <div className="ml-4 mr-4 relative max-w-full">
                    <h2 className="text-2xl">ЛОТ №{index}</h2>
                    <h3 className="text-xl mt-2">{product.name}</h3>
                    <h3 className="text-xl mt-2">{product.price}€</h3>
                    <div
                        className="mt-4 ml-4 mr-4 flex space-x-4 max-w-full overflow-x-auto">
                        {product.images.map((image) => {
                            return <div className="m-auto">
                                <img
                                    src={image}
                                    onClick={() => onShowFullImageClick(image)}
                                    className="max-h-[280px] h-[280px] rounded-lg m-auto max-w-[280px] w-[280px] object-contain"/>
                            </div>;
                        })}
                    </div>
                    <h2 className="text-lg mt-2">{product.year}</h2>
                    <h2 className="text-lg mt-2">{product.country}</h2>
                    <div className="bg-[#6C1414] mt-2 h-[4px] w-[200px] m-auto"/>
                    <h2 className="ml-16 mr-16 text-[#818181] text-base mt-2 mb-2">{product.description}</h2>
                </div>
            </div>
        </div>
        {state.isVisible && <SingleImageFullDialog
            imageUrl={state.imageUrl}
            isVisible={state.isVisible}
            handleVisibility={handleDialogVisibility}/>}
    </Dialog>
}