import {useCallback, useEffect, useState} from "react";
import {ProductsState, initGoodsState} from "./ProductsState";
import {ProductsResponse} from "../../../data/products/responses/ProductsResponse";
import {ProductResponse} from "../../../data/products/responses/ProductResponse";
import {ProductUiConverter} from "../converter/ProductUiConverter";
import {useNavigate} from "react-router-dom";
import {CaveCavistaRoutes} from "../../../routing/CaveCavistaRoutes";
import {useLoadProductsMutation, useSearchProductsMutation} from "../../../data/products/ProductsApi";
import {debounce} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../domain/store";
import {clearProducts, updateProducts, updateSort} from "../../../data/products/ProductsSlice";
import {useLoadConfigMutation} from "../../../data/config/ConfigApi";

export default function ProductsViewModel() {

    const profileReducer = useSelector((state: RootState) => state.auth)
    const productsReducer = useSelector((state: RootState) => state.products)

    const [search, setSearch] = useState<string>("")
    const [state, setState] = useState<ProductsState>(initGoodsState(!productsReducer.sort))

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [load] = useLoadProductsMutation()
    const [loadConfig] = useLoadConfigMutation()
    const [searchMutation] = useSearchProductsMutation()

    const [offset, setOffset] = useState(productsReducer.offset)

    window.addEventListener("beforeunload", (ev) => {
        dispatch(clearProducts())
        return true
    });

    function loadProducts(offset: number, sort: boolean | null, clearProducts: boolean) {

        async function loadGoods() {

            let sortDirection: number
            if (sort === null) {
                sortDirection = 0
            } else if (sort) {
                sortDirection = 1
            } else {
                sortDirection = 2
            }

            return await load({offset: offset, sort: sortDirection}).unwrap()
        }

        loadGoods().then((products: ProductsResponse) => {
            setOffset(products.offset)
            setItems(products.items, sort, clearProducts)
            dispatch(updateProducts(products))
        }).catch((e) => {
            setState({
                ...state,
                isLoading: false,
                error: e.status
            })
        })
    }

    async function setItems(items: ProductResponse[], sort: boolean | null, clearProducts: boolean) {
        const response = await loadConfig().unwrap()
        const uiItems = items.map((model: ProductResponse) => ProductUiConverter(model))
        let newProducts = []
        if (clearProducts) {
            newProducts = uiItems
        } else {
            newProducts = state.items.concat(uiItems)
        }

        if (uiItems.length > 0) {
            setState({
                ...state,
                sort: sort,
                error: null,
                isLoading: false,
                items: newProducts,
                rate: response.rate ?? 100,
                isEmpty: newProducts.length === 0,
                isAdmin: profileReducer.role === "admin",
            })
        }
    }

    const onPriceSortClicked = () => {
        let sort: boolean | null
        if (state.sort === null) {
            sort = true
        } else {
            sort = !state.sort
        }
        dispatch(clearProducts())
        dispatch(updateSort(state.sort))
        loadProducts(0, sort, true)
    }

    const onCreateItemClicked = () => {
        navigate(CaveCavistaRoutes.createItem)
    }

    const onReloadPageClick = () => {
        loadProducts(0, state.sort, false)
    }

    useEffect(() => {
        if (search !== "") {
            verify(search);
        } else {
            if (productsReducer.items.length > 0) {
                setItems(productsReducer.items, state.sort, false)
            } else {
                loadProducts(0, state.sort, false)
            }
        }
    }, [search]);

    const verify = useCallback(debounce(search => {
        searchProducts(search)
            .then((products: ProductsResponse) => {
                const uiItems = products.items.map((model: ProductResponse) => ProductUiConverter(model))
                setState({
                    ...state,
                    error: null,
                    items: uiItems,
                    isLoading: false,
                    isEmpty: uiItems.length === 0,
                    isAdmin: profileReducer.role === "admin",
                })
            })
            .catch((e) => {
                setState({
                    ...state,
                    items: [],
                    isLoading: false,
                    error: e.message,
                    isAdmin: profileReducer.role === "admin",
                })
            })
    }, 0), []);

    async function searchProducts(search: string) {
        return await searchMutation({query: search, onlySells: false}).unwrap()
    }

    const loadMoreButton = () => {
        loadProducts(offset, state.sort, false)
    }

    const onClearFilter = () => {
        dispatch(clearProducts())
        dispatch(updateSort(null))
        setSearch("")
        loadProducts(0, null, true)
    }

    return {
        state,
        search,
        setSearch,
        onClearFilter,
        loadMoreButton,
        onReloadPageClick,
        onPriceSortClicked,
        onCreateItemClicked
    }

}