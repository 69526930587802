import {ItemUi} from "../../products/main/ItemUi";


export interface PurchasedState {
    isLoading: boolean
    items: ItemUi[]
    isEmpty: boolean
    price: number
    error: string | null
}

export function initPurchasedState(): PurchasedState {
    return {
        isLoading: true,
        items: [],
        price: 0,
        isEmpty: false,
        error: null
    }
}
