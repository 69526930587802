import useViewModel from "./ProductsViewModel"
import {ProductsTable} from "./ProductsTable";
import {defaultLoadingOptions} from "../../../ui/CommonItems";
import {useLottie} from "lottie-react";
import React from "react";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SortIcon from '@mui/icons-material/Sort';
import CloseIcon from "@mui/icons-material/Close";

export function ProductsPage() {
    const {
        state,
        search,
        setSearch,
        onClearFilter,
        loadMoreButton,
        onReloadPageClick,
        onPriceSortClicked,
        onCreateItemClicked,
    } = useViewModel()
    const {View} = useLottie(defaultLoadingOptions);
    return (<div className="bg-black pt-2">
        <div className="flex ml-4 mr-4 justify-between items-center relative lg:pt-4 pt-2 lg:pb-4 pb-2">
            <div>
                <h1 className="lg:text-xl text-base text-center font-bold">Страница лотов</h1>
                <p className="text-[#818181] text-center m-auto">1€ = {state.rate}руб</p>
            </div>
            {!state.isLoading && state.error === null &&
                <div
                    className="hidden lg:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[360px] pb-2">
                    <input
                        className="w-full bg-[#191919] p-4 rounded-2xl text-white outline-gray-400 outline-0"
                        placeholder={"Поиск вина"}
                        value={search}
                        onChange={(field) => setSearch(field.target.value)}
                    />
                </div>}
            {state.error == null && <div className="flex space-x-2">
                {state.isAdmin && <button
                    onClick={onCreateItemClicked}
                    className="text-white h-fit bg-[#6C1414] items-center flex rounded-xl pt-2 pb-2 pl-4 pr-4">
                    Добавить лот
                </button>}
                <div className="lg:block hidden">
                    <div
                        className="flex space-x-2 items-center text-white bg-[#6C1414] rounded-xl pt-2 pb-2 pl-4 pr-4">
                        <button
                            onClick={onPriceSortClicked}>
                            Сортировка по цене
                        </button>
                        {state.sort ? <ArrowDownwardIcon color={"primary"}/> : <ArrowUpwardIcon color={"primary"}/>}
                    </div>
                    <h3
                        onClick={onClearFilter}
                        className="underline text-white text-center cursor-pointer">В порядке выдачи</h3>
                </div>
            </div>}
        </div>
        {(!state.isLoading && state.error == null) && <div>
            {!state.isLoading && state.error === null &&
                <div className="block pl-4 pr-4 lg:hidden w-full relative pb-2">
                    <input
                        className="w-full bg-[#191919] p-4 rounded-2xl text-white outline-gray-400 outline-0"
                        placeholder={"Поиск вина"}
                        value={search}
                        onChange={(field) => setSearch(field.target.value)}
                    />
                    <div
                        className="absolute right-6 top-3 lg:hidden block flex space-x-2">
                        {state.sort ? <SortIcon
                                onClick={onPriceSortClicked}
                                fontSize={"large"}
                                style={{color: "white"}}
                                className="rotate-180"/> :
                            <SortIcon
                                onClick={onPriceSortClicked}
                                fontSize={"large"}
                                style={{color: "white"}}/>}
                        {state.sort !== null && <div
                            onClick={onClearFilter}>
                            <CloseIcon style={{color: "white"}} fontSize={"large"}/>
                        </div>}
                    </div>
                </div>}
            <ProductsTable models={state.items} loadMoreButton={loadMoreButton}/>
        </div>}
        {state.isLoading && <div
            className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {View}
        </div>}
        {state.isEmpty && <div
            className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#818181] uppercase text-xl">
            Пока нет лотов
        </div>}
        {state.error !== null && <div
            className="absolute z-20 space-y-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h2 className="text-white">Ошибка: {state.error}</h2>
            <button
                className="w-full bg-[#6C1414] rounded-md p-2 text-white active:opacity-100 focus:opacity-100"
                onClick={onReloadPageClick}>Перезагрузить
            </button>
        </div>}
    </div>)
}