import {useEffect, useState} from "react";
import {
    AuctionScreenState,
    ConnectedUser,
    ConnectedUsers,
    initAuctionState,
    initPriceData,
    initProductUi,
    PriceData
} from "./AuctionState";
import {AuctionWebSocketTypes} from "../../domain/events/HandleEventTypes";
import {RootState} from "../../domain/store";
import {useDispatch, useSelector} from "react-redux";
import {ProductResponse} from "../../data/products/responses/ProductResponse";
import {ProductUiConverter} from "../products/converter/ProductUiConverter";
import {EventResponseConverter} from "./converter/EventResponseConverter";
import {useNavigate} from "react-router-dom";
import {CaveCavistaRoutes} from "../../routing/CaveCavistaRoutes";
import {AuctionState} from "./models/AuctionState";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {WS_URL} from "../../constants/Constants";
import {useAlert} from "../main/AlertManager";
import {ItemUi} from "../products/main/ItemUi";
import {authSlice} from "../../data/auth/AuthSlice";

export default function AuctionViewModel() {

    const [screenState, setScreenState] = useState<AuctionScreenState>(initAuctionState())

    const profileReducer = useSelector((state: RootState) => state.auth)

    const [price, setPrice] = useState<PriceData>(initPriceData())
    const [isAdmin, setIsAdmin] = useState(profileReducer.role == "admin")
    const [users, setUsers] = useState<ConnectedUsers>({users: [], isUsersVisible: false})
    const [userSid, setUserSid] = useState<string>(profileReducer.id + new Date().toLocaleString())

    const showAlert = useAlert()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const eventConverter = new EventResponseConverter()

    const waitingTimeLabel = "ожидайте"

    const {sendJsonMessage, lastMessage, readyState} = useWebSocket(WS_URL, {
        share: true,
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 300
    });

    useEffect(() => {
        console.log(readyState)
        if (readyState === ReadyState.OPEN) {
            sendJoinUserEvent()
        }
    }, [readyState])

    function sendJoinUserEvent() {
        sendJsonMessage({
            userSocketSid: userSid,
            userId: profileReducer.id,
            token: profileReducer.token,
            username: profileReducer.name,
            type: AuctionWebSocketTypes.userJoined,
            isAdmin: profileReducer.role === "admin"
        });
    }

    useEffect(() => {
        try {
            if (lastMessage != null) {
                const event = JSON.parse(lastMessage.data)
                if (event.type === AuctionWebSocketTypes.userJoined) {
                    handleJoinEvent(event)
                } else if (event.type === AuctionWebSocketTypes.preparing) {
                    auctionPreparingEvent(event)
                } else if (event.type === AuctionWebSocketTypes.started) {
                    auctionStartedEvent(event)
                } else if (event.type === AuctionWebSocketTypes.userWin) {
                    auctionUserWin(event)
                } else if (event.type === AuctionWebSocketTypes.closeDialog) {
                    auctionCloseUserWinDialog(event)
                } else if (event.type === AuctionWebSocketTypes.finish) {
                    auctionFinishedEvent(event)
                } else if (event.type === AuctionWebSocketTypes.paused) {
                    auctionPauseEvent(event)
                } else if (event.type === AuctionWebSocketTypes.changeBet) {
                    changeBetEvent(event)
                } else if (event.type === AuctionWebSocketTypes.changeProduct) {
                    changeProductEvent(event)
                } else if (event.type === AuctionWebSocketTypes.logout) {
                    dispatch(authSlice.actions.clearAuth())
                } else if (event.type === AuctionWebSocketTypes.usersCount) {
                    handleUsers(event)
                } else if (event.type === AuctionWebSocketTypes.userDisconnected) {
                    handleUserDisconnect(event)
                } else if (event.type === AuctionWebSocketTypes.changeBetError) {
                    const response = eventConverter.convertChangeBetError(event)
                    showAlert(response.message, 'error')
                }
            }
        } catch (e: any) {
            console.log(e)
            setScreenState({
                ...screenState,
                isLoading: false,
                error: e.message
            })
            showAlert("Произошла ошибка", 'error')
        }
    }, [lastMessage])

    function handleUserDisconnect(data: any) {
        try {
            const event = eventConverter.convertUserDisconnectEvent(data)
            setUsers({
                ...users,
                users: users.users.filter((user) => user.userSid !== event.userSid)
            })
        } catch (e) {
            console.log(e)
        }
    }

    function handleUsers(data: any) {
        try {
            const event = eventConverter.convertUsersEvent(data)
            setUsers({
                ...users,
                users: event.users.map((user) => mapUser(user))
            })
        } catch (e) {
            console.log(e)
        }
    }

    function mapUser(user: any): ConnectedUser {
        return {
            userId: user.userId,
            username: user.name,
            userSid: user.userSid
        }
    }

    function changeProductEvent(data: any) {
        const event = eventConverter.convertChangeProductEvent(data)
        setPrice({
            inputPrice: Number(event.product.price),
            currentPrice: event.product.price ?? "0"
        })
        setScreenState({
            ...screenState,
            isLoading: false,
            data: {
                ...screenState.data,
                currentItem: ProductUiConverter(event.product),
                nextProductVisible: false,
                isUserWinProductVisible: false,
            }
        })
    }

    function changeBetEvent(data: any) {
        const event = eventConverter.convertChangeBetEvent(data)
        setPrice({
            inputPrice: event.price + screenState.data.betStep,
            currentPrice: event.price.toString()
        })
        setScreenState({
            ...screenState,
            isLoading: false,
        })
        showAlert(`Ставка поднята до ${event.price}€ и таймер обновлен`, 'success')
    }

    function auctionPauseEvent(data: any) {
        const event = eventConverter.convertPauseEvent(data)
        setScreenState({
            ...screenState,
            isLoading: false,
            data: {
                ...screenState.data,
                isResumeButtonEnabled: true,
                isPauseButtonEnabled: false,
                auctionState: AuctionState.PAUSE,
                isPauseDialogVisible: event.isPaused,
                startAuctionText: "Возобновить торги",
            }
        })
    }

    function auctionFinishedEvent(data: any) {
        setScreenState({
            ...screenState,
            isLoading: false,
            data: {
                ...screenState.data,
                auctionState: AuctionState.FINISH,
            }
        })
    }

    function auctionUserWin(data: any) {
        const event = eventConverter.convertUserWin(data)
        const currentItem = ProductUiConverter(event.product)

        setPrice({
            currentPrice: event.price.toString(),
            inputPrice: event.price
        })

        setScreenState({
            ...screenState,
            isLoading: false,
            data: {
                ...screenState.data,
                currentItem: currentItem,
                isUserWinProductVisible: event.isSoldProduct,
                nextProductVisible: !event.isSoldProduct,
                currentTime: waitingTimeLabel,
            }
        })
    }

    function auctionCloseUserWinDialog(data: any) {
        setScreenState({
            ...screenState,
            isLoading: false,
            data: {
                ...screenState.data,
                nextProductVisible: false,
                isUserWinProductVisible: false,
            }
        })
    }

    function handleJoinEvent(data: any) {

        const event = eventConverter.convertJoinEvent(data)
        const uiItems = event.products.items.map((model: ProductResponse) => ProductUiConverter(model))

        let startAuctionText = ""
        if (event.auctionState === AuctionState.PAUSE) {
            startAuctionText = "Возобновить торги"
        } else {
            startAuctionText = "Начать торги"
        }

        let time = ""
        if (event.auctionState === AuctionState.USER_WIN) {
            time = waitingTimeLabel
        } else {
            time = event.timeLeft + " секунд"
        }

        const isStartButtonEnabled = uiItems.filter((item) => item.user == null).length === 0

        setPrice({
            inputPrice: Number(event.price) ?? 0,
            currentPrice: event.product?.price ?? "0",
        })

        setScreenState({
            ...screenState,
            isLoading: false,
            error: null,
            data: {
                ...screenState.data,
                items: uiItems,
                currentTime: time,
                betStep: event.betStep,
                rate: event.rate ?? 100,
                startTime: event.startTime,
                startDate: event.startDate,
                productsSize: event.productsSize,
                auctionState: event.auctionState,
                startAuctionText: startAuctionText,
                isStartButtonEnabled: isStartButtonEnabled,
                isPauseDialogVisible: event.auctionState === AuctionState.PAUSE,
                isPauseButtonEnabled: event.auctionState !== AuctionState.PAUSE,
                isResumeButtonEnabled: event.auctionState === AuctionState.PAUSE,
                isFinishButtonEnabled: event.auctionState !== AuctionState.FINISH,
                currentItem: event.product ? ProductUiConverter(event.product) : initProductUi(),
                nextProductVisible: event.auctionState === AuctionState.USER_WIN && event?.product?.soldPrice === null,
                isUserWinProductVisible: event.auctionState === AuctionState.USER_WIN && event?.product?.soldPrice !== null,
            },
        })

    }

    function auctionStartedEvent(data: any) {

        const event = eventConverter.convertStartedEvent(data)
        const newItem = ProductUiConverter(event.product)
        const uiItems = event.products.items.map((model: ProductResponse) => ProductUiConverter(model))
        const index = screenState.data.items.findIndex((item) => item.id === newItem.id)

        setPrice({
            ...price,
            currentPrice: event.currentBet.toString(),
        })
        setScreenState({
            ...screenState,
            isLoading: false,
            data: {
                ...screenState.data,
                items: uiItems,
                currentItem: newItem,
                betStep: event.betStep,
                startAuctionText: "Начать торги",
                isPauseButtonEnabled: true,
                currentItemIndex: index + 1,
                isPauseDialogVisible: false,
                isResumeButtonEnabled: false,
                productsSize: event.productsSize,
                isStartButtonEnabled: false,
                isFinishButtonEnabled: true,
                auctionState: AuctionState.STARTED,
                currentTime: event.timeLeft.toString() + " секунд",
            }
        })
    }

    function auctionPreparingEvent(data: any) {
        const event = eventConverter.convertPreparingEvent(data)
        const products = event.products.items.map((product) => ProductUiConverter(product))
        setScreenState({
            ...screenState,
            isLoading: false,
            data: {
                ...screenState.data,
                auctionState: event.type,
                delay: event.delay.toString(),
                items: products
            }
        })
    }

    const onReloadPageClicked = () => {

    }

    const onStartAuctionClicked = () => {
        sendJsonMessage({
            type: AuctionWebSocketTypes.preparing,
            username: profileReducer.name,
            userId: profileReducer.id,
            token: profileReducer.token
        });
    }

    const onResumeAuctionClicked = () => {
        sendJsonMessage({
            type: AuctionWebSocketTypes.started,
            username: profileReducer.name,
            userId: profileReducer.id,
            token: profileReducer.token
        });
    }

    const onPrimaryButtonAuctionClicked = () => {
        sendJsonMessage({
            type: AuctionWebSocketTypes.paused,
            username: profileReducer.name,
            userId: profileReducer.id,
            token: profileReducer.token
        });
    }

    const onFinishAuctionClicked = () => {
        sendJsonMessage({
            type: AuctionWebSocketTypes.finish,
            username: profileReducer.name,
            userId: profileReducer.id,
            token: profileReducer.token
        });
    }

    const onInputPriceChanged = (priceValue: number) => {
        setPrice({
            ...price,
            inputPrice: priceValue
        })
    }

    const onMakeBetClicked = () => {
        sendJsonMessage({
            type: AuctionWebSocketTypes.changeBet,
            username: profileReducer.name,
            userId: profileReducer.id,
            token: profileReducer.token,
            price: price.inputPrice
        });
    }

    const onShowProductsClicked = () => {
        navigate(CaveCavistaRoutes.products)
    }

    const onShowFinishProducts = () => {
        if (isAdmin) {
            navigate(CaveCavistaRoutes.sells)
        } else {
            navigate(CaveCavistaRoutes.purchased)
        }
    }

    const onItemClicked = (newItem: ItemUi) => {
        const index = screenState.data.items.findIndex((item) => item.id === newItem.id)
        setScreenState({
            ...screenState,
            data: {
                ...screenState.data,
                isFullItemDialogVisible: {
                    isVisible: true,
                    index: index + 1,
                    item: newItem
                }
            }
        })
    }

    const onHandleVisibility = () => {
        setScreenState({
            ...screenState,
            data: {
                ...screenState.data,
                isFullItemDialogVisible: {
                    ...screenState.data.isFullItemDialogVisible,
                    isVisible: false,
                }
            }
        })
    }

    const onCloseDialogClick = () => {
        setScreenState({
            ...screenState,
            data: {
                ...screenState.data,
                nextProductVisible: false,
                isUserWinProductVisible: false
            }
        })
    }

    const onShowUsersClick = () => {
        setUsers({
            ...users,
            isUsersVisible: !users.isUsersVisible
        })
    }

    return {
        state: screenState,
        isAdmin,
        price,
        users,
        onItemClicked,
        onMakeBetClicked,
        onShowUsersClick,
        onHandleVisibility,
        onCloseDialogClick,
        onReloadPageClicked,
        onInputPriceChanged,
        onShowFinishProducts,
        onShowProductsClicked,
        onStartAuctionClicked,
        onResumeAuctionClicked,
        onFinishAuctionClicked,
        onPrimaryButtonAuctionClicked
    }


}