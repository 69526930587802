import React, {useState} from "react";
import {AuctionScreenState} from "../AuctionState";

export interface AuctionIdleComponentProps {
    state: AuctionScreenState
    isAdmin: boolean
    onShowProductsClicked: () => void
    onStartAuctionClicked: () => void
}

export function AuctionIdleComponent({state, isAdmin, onStartAuctionClicked, onShowProductsClicked}: AuctionIdleComponentProps) {
    const [isButtonEnabled, setIsButtonEnabled] = useState(!state.data.isStartButtonEnabled)
    return <div
        className="lg:w-fit lg:pl-0 pl-4 pr-4 lg:pr-0 w-full absolute z-20 top-1/2 left-1/2 text-white text-center transform -translate-x-1/2 -translate-y-1/2">
        <div className="space-y-4">
            <h2>Аукцион еще не начался!</h2>
            <div>
                <h3>Но он начнется {state.data.startDate} в {state.data.startTime}</h3>
                <h3>Пока вы можете посмотреть на интересующие вас лоты!</h3>
            </div>
            <button
                onClick={onShowProductsClicked}
                className="w-fit rounded-xl bg-[#6C1414] text-white pt-2 pb-2 pl-4 pr-4">Перейти к лотам
            </button>
        </div>
        {isAdmin && <div className="mt-16">
            <button
                disabled={!isButtonEnabled}
                onClick={() => {
                    setIsButtonEnabled(false)
                    onStartAuctionClicked()
                }}
                className={`w-full cursor-pointer rounded-xl bg-[#6C1414] text-white 
                pt-2 pb-2 pl-4 pr-4 ${isButtonEnabled ? "bg-[#6C1414]" : "bg-gray-800"}`}>
                {isButtonEnabled ? "Запустить аукцион" : "Все лоты раскуплены"}
            </button>
            <p className="text-[#818181]">Видно только админу</p>
        </div>}
    </div>
}