import {UserResponse} from "../../../data/auth/responses/UserResponse";
import {ButtonType, UserUi} from "../main/UsersState";


export function UserUiConverter(item: UserResponse): UserUi {

    let fullName = ""
    if (item.name !== "") {
        fullName = item.name ?? "Имя не указано"
    } else {
        fullName = "Имя не указано"
    }

    return {
        id: item.id,
        address: item.address ?? "",
        fullName: fullName,
        login: item.login,
        password: item.password,
        contacts: item.contacts ?? "",
        buttonType: ButtonType.EDIT
    }
}