import {ItemUi} from "./ItemUi";

export interface ProductsState {
    isLoading: boolean
    items: ItemUi[]
    rate: number
    sort: boolean | null
    isEmpty: boolean
    isAdmin: boolean
    error: string | null
}

export function initGoodsState(sort: boolean | null): ProductsState {
    return {
        isLoading: true,
        items: [],
        isAdmin: false,
        rate: 100,
        sort: sort,
        isEmpty: false,
        error: null
    }
}