import {useNavigate} from "react-router-dom";
import logo from "../../assets/logo.svg"
import {CaveCavistaRoutes} from "../../routing/CaveCavistaRoutes";
import {MobileDrawerComponent} from "./MobileDrawerComponent";
import {useState} from "react";
import icMenu from "../../assets/burger.svg"
import {ProfileMenuWidget} from "../../ui/ProfileMenuWidget";

export function MobileCavistaHeader() {
    const [open, isOpen] = useState(false)
    const navigate = useNavigate()
    return <>
        <div className="block lg:hidden relative flex items-center justify-between bg-black h-20">
            <div
                onClick={() => isOpen(!open)}
                className="pl-4">
                <img src={icMenu}/>
            </div>
            <div
                onClick={() => navigate(CaveCavistaRoutes.auction)}
                className="flex items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-shrink-0 m-auto cursor-pointer space-x-2 text-white">
                <img src={logo} width="24px" height="24px"/>
                <h1 className="font-semibold text-xl tracking-tight text-[#6C1414]">Cave Cavista</h1>
            </div>
            <div
                onClick={() => navigate(CaveCavistaRoutes.purchased)}
                className="flex justify-between">
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                    <ProfileMenuWidget/>
                </div>
            </div>
            <MobileDrawerComponent isOpen={open} setIsOpen={isOpen}/>
        </div>
        <div className="w-full h-0.5 bg-[#191919] absolute bottom-1"/>
    </>
}