export interface CavistaInputProps {
    onChanged: (text: string) => void
    text: string
    disabled ?: boolean
    value: string
}

export function CavistaTextArea({onChanged, disabled, value, text}: CavistaInputProps) {
    return <div className="w-full min-w-[200px]">
        <textarea
            className="w-full bg-[#191919] min-h-[300px] p-4 rounded-2xl text-white outline-gray-400 outline-0"
            placeholder={text}
            disabled={disabled}
            value={value}
            onChange={(field) => onChanged(field.target.value)}
        />
    </div>
}