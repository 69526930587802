import {ItemUi} from "../products/main/ItemUi";
import {UserUi} from "../users/main/UsersState";

export interface SellsItemUi {
    user: UserUi
    products: ItemUi[]
}

export interface SellsState {
    isLoading: boolean
    items: SellsItemUi[]
    isEmpty: boolean
    isAdmin: boolean
    error: string | null
}

export function initSellsState(): SellsState {
    return {
        isLoading: true,
        items: [],
        isAdmin: false,
        isEmpty: false,
        error: null
    }
}