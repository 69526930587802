import {useCallback, useEffect, useState} from "react";
import {initUsersState, UsersState} from "./UsersState";
import {CaveCavistaRoutes} from "../../../routing/CaveCavistaRoutes";
import {useNavigate} from "react-router-dom";
import {UserResponse} from "../../../data/auth/responses/UserResponse";
import {useLoadUsersMutation, useSearchUsersMutation} from "../../../data/auth/AuthApi";
import {UsersResponse} from "../../../data/auth/responses/UsersResponse";
import {UserUiConverter} from "../converter/UserUiConverter";
import {debounce} from "@mui/material";


export default function UsersViewModel() {

    const [state, setState] = useState<UsersState>(initUsersState())
    const [search, setSearch] = useState<string>("")

    const navigate = useNavigate()

    const [loadAll] = useLoadUsersMutation()
    const [searchMutation] = useSearchUsersMutation()

    useEffect(() => {
        fetchData()
    }, [])

    function fetchData() {

        async function getSingleUser() {
            return await loadAll().unwrap()
        }

        getSingleUser().then((response: UsersResponse) => {
            setState({
                ...state,
                users: response.users.map((user: UserResponse) => UserUiConverter(user)),
                isLoading: false,
                error: null
            })
        }).catch((e) => {
            setState({
                ...state,
                isLoading: false,
                error: e.status
            })
        })
    }

    useEffect(() => {
        if (search !== "") {
            verify(search);
        } else {
            fetchData()
        }
    }, [search]);

    async function searchUsers(search: string) {
        return await searchMutation({query: search}).unwrap()
    }

    const verify = useCallback(debounce(search => {
        searchUsers(search)
            .then((response: UsersResponse) => {
                const uiItems = response.users.map((user: UserResponse) => UserUiConverter(user))
                setState({
                    ...state,
                    users: uiItems,
                    isLoading: false,
                    isEmpty: uiItems.length === 0,
                    error: null
                })
            }).catch((e) => {
            console.log(e)
            setState({
                ...state,
                users: [],
                isLoading: false,
                error: e.message
            })
        })
    }, 0), []);

    const onEditClicked = (userId: number) => {
        navigate(CaveCavistaRoutes.users + "/" + userId, {replace: false})
    }

    const onCreateUserClicked = () => {
        navigate(CaveCavistaRoutes.createUser, {replace: false})
    }

    const onReloadPageClick = () => {
        fetchData()
    }

    return {
        state,
        search,
        setSearch,
        onEditClicked,
        onReloadPageClick,
        onCreateUserClicked
    }

}