import React, {useState} from "react";
import {SellsItemUi} from "./SellsState";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import {ProductsTable} from "../products/main/ProductsTable";

export interface SellsItemUiComponentProps {
    item: SellsItemUi
}

export function SellsItemUiComponent({item}: SellsItemUiComponentProps) {
    const [isOpen, setIsOpen] = useState(false)
    return <div
        onClick={() => setIsOpen(!isOpen)}
        key={item.user.id} className="cursor-pointer bg-[#320909] rounded-xl p-3 space-x-2">
        <div className="flex justify-between">
            <div className="flex items-center text-white space-x-2">
                <p>{item.user.fullName}</p>
                <p className="hidden lg:block">{item.user.login}</p>
                <p className="hidden lg:block">{item.user.password}</p>
                <p className="hidden lg:block">{item.user.address}</p>
                <p className="hidden lg:block">{item.user.contacts}</p>
            </div>
            <div>
                {isOpen && <ArrowDownward color={"primary"}/>}
                {!isOpen && <ArrowUpward color={"primary"}/>}
            </div>
        </div>
        {isOpen && <ProductsTable models={item.products} loadMoreButton={null} />}
    </div>
}