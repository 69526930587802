import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import {CaveCavistaRoutes} from "./CaveCavistaRoutes";
import {RootState} from "../domain/store";
import {NotExistsPage} from "../features/noAuth/NotExistsPage";
import {AuctionPage} from "../features/auction/AuctionPage";
import {ProductsPage} from "../features/products/main/ProductsPage";
import {SettingsPage} from "../features/settings/SettingsPage";
import {UsersPage} from "../features/users/main/UsersPage";
import {AuthorizationPage} from "../features/authorization/AuthorizationPage";
import {SingleUserPage} from "../features/users/single/SingleUserPage";
import {SingleItemPage} from "../features/products/single/SingleItemPage";
import {PurchasedPage} from "../features/users/purchased/PurchasedPage";
import {SearchPage} from "../features/search/SearchPage";
import {SellsPage} from "../features/sells/SellsPage";


export function MainRoutes() {

    const authReducer = useSelector((state: RootState) => state.auth)

    return <>
        <Routes>
            <Route index element={<Navigate to={CaveCavistaRoutes.auction} replace/>}/>
            <Route
                path={CaveCavistaRoutes.auction}
                element={authReducer.token !== "" ? <AuctionPage/> : <Navigate to={CaveCavistaRoutes.auth}/>}
            />

            <Route
                path={CaveCavistaRoutes.settings}
                element={authReducer.token === "" ?
                    <Navigate to={CaveCavistaRoutes.auth}/> : authReducer.role !== "admin" ?
                        <Navigate to={CaveCavistaRoutes.nonAuthPage}/> : <SettingsPage/>}
            />

            <Route path="*" element={<NotExistsPage/>}/>
            <Route path={CaveCavistaRoutes.auth} element={<AuthorizationPage/>}/>
            <Route
                path={CaveCavistaRoutes.users}
                element={authReducer.token === "" ?
                    <Navigate to={CaveCavistaRoutes.auth}/> : authReducer.role !== "admin" ?
                        <Navigate to={CaveCavistaRoutes.nonAuthPage}/> : <UsersPage/>}/>
            <Route
                path={CaveCavistaRoutes.users + "/" + CaveCavistaRoutes.createUser}
                element={authReducer.token === "" ?
                    <Navigate to={CaveCavistaRoutes.auth} replace={false}/> : authReducer.role !== "admin" ?
                        <Navigate to={CaveCavistaRoutes.nonAuthPage}/> : <SingleUserPage/>}/>
            <Route
                path={CaveCavistaRoutes.users + "/:userId"}
                element={authReducer.token === "" ?
                    <Navigate to={CaveCavistaRoutes.auth} replace={false}/> : authReducer.role !== "admin" ?
                        <Navigate to={CaveCavistaRoutes.nonAuthPage}/> : <SingleUserPage/>}/>
            <Route
                path={CaveCavistaRoutes.purchased}
                element={authReducer.token !== "" ? <PurchasedPage/> : <Navigate to={CaveCavistaRoutes.auth}/>}/>
            <Route
                path={CaveCavistaRoutes.search}
                element={authReducer.token !== "" ? <SearchPage/> : <Navigate to={CaveCavistaRoutes.search}/>}/>
            <Route
                path={CaveCavistaRoutes.products}
                element={authReducer.token !== "" ? <ProductsPage/> : <Navigate to={CaveCavistaRoutes.auth}/>}/>
            <Route
                path={CaveCavistaRoutes.sells}
                element={authReducer.token === "" ?
                    <Navigate to={CaveCavistaRoutes.auth}/> : authReducer.role !== "admin" ?
                        <Navigate to={CaveCavistaRoutes.nonAuthPage}/> : <SellsPage/>}/>
            <Route
                path={CaveCavistaRoutes.products + "/" + CaveCavistaRoutes.createItem}
                element={authReducer.token !== "" ? <SingleItemPage/> :
                    <Navigate to={CaveCavistaRoutes.auth} replace={false}/>}/>
            <Route
                path={CaveCavistaRoutes.products + "/:productId"}
                element={authReducer.token !== "" ? <SingleItemPage/> :
                    <Navigate to={CaveCavistaRoutes.auth} replace={false}/>}/>

            <Route path={CaveCavistaRoutes.nonAuthPage} element={<NotExistsPage/>}/>
        </Routes>
    </>
}