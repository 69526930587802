//Local localhost
//Yandex 51.250.82.161

// const yandexPath = "fables.tech"
// const localhostPathDocker = "backend"
// const localhostPath = "localhost:3005"

export const ServerUrl: string = `${process.env.REACT_APP_DOMAIN}/api`;

//export const ServerUrl: string = `http://localhost:3005/api`;

//cavecavista.online

export const WS_URL = `wss://cavecavista.online/api/events`