import useViewModel from "./UsersViewModel"
import {useLottie} from "lottie-react";
import {defaultLoadingOptions} from "../../../ui/CommonItems";
import {CavistaInput} from "../../../ui/CavistaInput";
import React from "react";
import icEdit from '../../../assets/ic_edit.svg'

export function UsersPage() {
    const {state, search, setSearch, onEditClicked, onCreateUserClicked, onReloadPageClick} = useViewModel()
    const {View} = useLottie(defaultLoadingOptions)
    return (<div className="ml-4 mr-4 pt-2">
        <div className="flex justify-between items-center pt-4 pb-4 relative">
            <h1 className="lg:text-xl text-base font-bold">Страница пользователей</h1>
            {!state.isLoading && state.error === null && <div className="lg:block hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[360px] pb-2">
                <CavistaInput
                    value={search}
                    text={"Поиск пользователей"}
                    onChanged={(item) => setSearch(item)}
                />
            </div>}
            {!state.isLoading && state.error == null && <div>
                <button
                    onClick={onCreateUserClicked}
                    className="text-white bg-[#6C1414] items-center flex rounded-xl pt-2 pb-2 pl-4 pr-4">
                    Добавить
                </button>
            </div>}
        </div>
        {(!state.isEmpty && !state.isLoading && state.error == null) &&
            <div className="space-y-4 h-[calc(100vh-165px)] overflow-y-scroll">
                {!state.isLoading && state.error === null && <div className="lg:hidden block w-full pb-2">
                    <CavistaInput
                        value={search}
                        text={"Поиск пользователей"}
                        onChanged={(item) => setSearch(item)}
                    />
                </div>}
                {state.users.map((user, index) => {
                        return (<div key={user.id} className="bg-[#320909] rounded-xl p-3 space-x-2">
                            <div className="flex justify-between">
                                <div className="flex items-center text-white space-x-2">
                                    <p>{user.fullName}</p>
                                    <p className="hidden lg:block">{user.login}</p>
                                    <p className="hidden lg:block">{user.password}</p>
                                    <p className="hidden lg:block">{user.address}</p>
                                    <p className="hidden lg:block">{user.contacts}</p>
                                </div>
                                <div>
                                    <button
                                        onClick={() => onEditClicked(user.id)}
                                        className="hidden lg:block text-white bg-[#6C1414] rounded-xl pt-1 pb-1 pl-4 pr-4">
                                        Редактировать
                                    </button>
                                    <img
                                        onClick={() => onEditClicked(user.id)}
                                        src={icEdit}
                                        className="block lg:hidden bg-[#6C1414] rounded-xl p-2"/>
                                </div>
                            </div>
                        </div>);
                    },
                )}
            </div>}
        {state.isLoading &&
            <div
                className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                {View}
            </div>
        }
        {state.error !== null &&
            <div
                className="absolute z-20 space-y-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <h2 className="text-white">Ошибка: {state.error}</h2>
                <button
                    className="w-full bg-[#6C1414] rounded-md p-2 text-white active:opacity-100 focus:opacity-100"
                    onClick={onReloadPageClick}>Перезагрузить
                </button>
            </div>
        }
    </div>)
}