import React from "react";
import {SellsItemUi} from "./SellsState";
import {SellsItemUiComponent} from "./SellsItemUi";
import {generateRandomString} from "../../domain/utils/Utils";

export interface SellsTableProps {
    users: SellsItemUi[]
}

export function SellsTable({users}: SellsTableProps) {
    return <div className="ml-4 mr-4 space-y-4 overflow-y-scroll h-full lg:max-h-[calc(100vh-200px)]">
        {users.map((sellItem) => {
            return <SellsItemUiComponent key={generateRandomString(10)} item={sellItem}/>
        })}
    </div>
}