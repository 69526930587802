import useViewModel from "./SingleUserViewModel"
import {SinglePageHeader} from "../../../ui/SinglePageHeader";
import {CavistaInput} from "../../../ui/CavistaInput";
import {useLottie} from "lottie-react";
import {defaultLoadingOptions} from "../../../ui/CommonItems";

export function SingleUserPage() {
    const {
        state,
        onLoginChanged,
        onContactsChanged,
        onFullNameChanged,
        onAddressChanged,
        onPasswordChanged,
        onReloadPageClick,
        onDeleteUserClicked,
        onPrimaryButtonClicked
    } = useViewModel()
    const {View} = useLottie(defaultLoadingOptions)
    return (<div>
        <SinglePageHeader title={state.data.title}/>
        {!state.isLoading && state.error == null && <div className="flex justify-center lg:h-[calc(100vh-72px)]">
            <div className="lg:w-[400px] w-full lg:mt-16 mt-4 pl-4 pr-4 lg:pl-0 lg:pr-4 space-y-2">
                <CavistaInput title={"ФИО"} onChanged={onFullNameChanged} value={state.data.fullName} text={"Игорь Владимирович"}/>
                <CavistaInput title={"Адрес"} onChanged={onAddressChanged} value={state.data.address} text={"Новоохтинская 11"}/>
                <CavistaInput title={"Контакты"} onChanged={onContactsChanged} value={state.data.contacts} text={"test@mail.ru +7934333242"}/>
                <CavistaInput title={"Логин"} onChanged={onLoginChanged} value={state.data.login} text={"login"}/>
                <CavistaInput title={"Пароль"} onChanged={onPasswordChanged} value={state.data.password} text={"password"}/>
                <div className="flex justify-center space-x-2 w-full">
                    {state.data.id !== null && <button
                        onClick={onDeleteUserClicked}
                        className="w-full rounded-xl hover:border-red-600 border-red-800 border-2 text-red-800">Удалить
                    </button>}
                    <button
                        onClick={onPrimaryButtonClicked}
                        className="w-full rounded-xl shadow bg-[#6C1414] text-white pt-2 pb-2 pl-4 pr-4">{state.primaryButtonText}
                    </button>
                </div>
            </div>
        </div>}
        {state.isLoading &&
            <div
                className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                {View}
            </div>
        }
        {state.error !== null &&
            <div
                className="absolute z-20 space-y-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <h2 className="text-white">Ошибка: {state.error}</h2>
                <button
                    className="w-full bg-[#6C1414] rounded-md p-2 text-white active:opacity-100 focus:opacity-100"
                    onClick={onReloadPageClick}>Перезагрузить
                </button>
            </div>
        }
    </div>)
}