import React from "react";
import Dialog from '@mui/material/Dialog';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export interface SingleImageDialogProps {
    imageUrl: string
    isVisible: boolean
    handleVisibility: () => void
}

export function SingleImageFullDialog({isVisible, imageUrl, handleVisibility}: SingleImageDialogProps) {
    return <Dialog open={isVisible} onClose={handleVisibility}>
        <div className="w-fit h-fit m-auto">
            <Zoom>
                <img src={imageUrl} className="object-contain m-auto"/>
            </Zoom>
        </div>
    </Dialog>
}