import {Drawer} from "@mui/material";
import {CaveCavistaRoutes} from "../../routing/CaveCavistaRoutes";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../domain/store";
import {useLocation, useNavigate} from "react-router-dom";
import {authSlice} from "../../data/auth/AuthSlice";

export interface MobileDrawerComponentProps {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}


export function MobileDrawerComponent({isOpen, setIsOpen}: MobileDrawerComponentProps) {

    const locate = useLocation()
    const navigate = useNavigate()


    const authReducer = useSelector((state: RootState) => state.auth)
    const isAdmin = authReducer.role === "admin"

    const menuStyle = "block mt-4 lg:inline-block lg:mt-0 text-white hover:text-[#6C1414] text-base"
    const menuStyleActive = "block mt-4 lg:inline-block lg:mt-0 text-[#6C1414] hover:text-[#6C1414] text-base"

    const isSettings = locate.pathname.includes(CaveCavistaRoutes.settings)
    const isUsers = locate.pathname.includes(CaveCavistaRoutes.users)
    const isAuction = locate.pathname.includes(CaveCavistaRoutes.auction)
    const isProducts = locate.pathname.includes(CaveCavistaRoutes.products)
    const isSells = locate.pathname.includes(CaveCavistaRoutes.sells)

    const dispatch = useDispatch()

    const onLogoutClicked = () => {
        dispatch(authSlice.actions.clearAuth())
        navigate(CaveCavistaRoutes.auth, {replace: true})
    }

    return <Drawer open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="w-full bg-black opacity-90 pl-8 pr-8 lg:items-center h-full">
            <div className="w-full items-center text-sm">
                <div
                    onClick={() => {
                        setIsOpen(false)
                        navigate(CaveCavistaRoutes.auction)
                    }}
                    className="h-full relative flex items-center cursor-pointer">
                    <div className={isAuction ? menuStyleActive : menuStyle}>
                        Аукцион
                    </div>
                </div>
                <div
                    onClick={() => {
                        setIsOpen(false)
                        navigate(CaveCavistaRoutes.products)
                    }}
                    className="h-full relative flex items-center cursor-pointer">
                    <div
                        className={isProducts ? menuStyleActive : menuStyle}>
                        Лоты
                    </div>
                </div>
                {isAdmin && <div
                    onClick={() => {
                        setIsOpen(false)
                        navigate(CaveCavistaRoutes.users)
                    }}
                    className="h-full relative flex items-center cursor-pointer">
                    <div
                        className={isUsers ? menuStyleActive : menuStyle}>
                        Пользователи
                    </div>
                </div>}
                {isAdmin && <div
                    onClick={() => {
                        setIsOpen(false)
                        navigate(CaveCavistaRoutes.sells)
                    }}
                    className="h-full relative flex items-center cursor-pointer">
                    <div
                        className={isSells ? menuStyleActive : menuStyle}>
                        Продажи
                    </div>
                </div>}
                {isAdmin && <div
                    onClick={() => {
                        setIsOpen(false)
                        navigate(CaveCavistaRoutes.settings)
                    }}
                    className="h-full relative flex items-center cursor-pointer">
                    <div
                        className={isSettings ? menuStyleActive : menuStyle}>
                        Настройки
                    </div>
                </div>}
                <div
                    onClick={() => {
                        setIsOpen(false)
                        onLogoutClicked()
                    }}
                    className="h-full mt-16 relative flex items-center cursor-pointer">
                    <div
                        className="w-full rounded-xl text-white pt-2 pb-2 pl-4 pr-4 bg-[#6C1414]">
                        Выход
                    </div>
                </div>
            </div>
        </div>
    </Drawer>
}