import React from "react";
import {ItemUi} from "../../products/main/ItemUi";

export interface FullProductComponentProps {
    product: ItemUi
}

export function MobileFullProductComponent({product}: FullProductComponentProps) {
    return (<div className="w-full h-full mt-4">
        <div className="bg-[#320909] rounded-lg h-full text-white text-center">
            <div className="p-4">
                <div className="relative max-w-full">
                    <div className="flex justify-center items-center space-x-2">
                        <h3 className="text-xl">{product.name}</h3>
                        <h3 className="text-xl">{product.price}€</h3>
                    </div>
                    <div className="space-x-2 mt-2">
                        <img
                            src={product.imageUrl}
                            className="max-h-[300px] m-auto h-[300px] rounded-lg max-w-[300px] w-[300px] object-contain"/>
                        <div className="m-auto w-full">
                            <h2 className="text-lg mt-2">{product.year}</h2>
                            <h2 className="text-lg mt-2">{product.country}</h2>
                            {product.description !== "" && <div className="bg-[#6C1414] mt-2 h-[4px] w-[200px] m-auto"/>}
                            <h2 className="text-[#818181] text-base mt-2 mb-2">{product.description}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)

}