import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ProfileMenuWidget} from "./ProfileMenuWidget";
import {useNavigate} from "react-router-dom";

export interface SinglePageHeaderProps {
    title: string
}

export function SinglePageHeader({title}: SinglePageHeaderProps) {
    const navigate = useNavigate()
    return <div className="relative h-20">
        <nav className="flex items-center h-20 justify-between flex-wrap bg-black p-4">
            <div className="flex items-center space-x-4 lg:ml-2 ml-0 mr-4">
                <div onClick={() => navigate(-1)}>
                    <ArrowBackIcon style={{ color: 'white' }}/>
                </div>
            </div>
            <h2 className="text-white">{title}</h2>
            <div className="flex justify-between">
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                    <ProfileMenuWidget/>
                </div>
            </div>
        </nav>
        <div className="w-full h-0.5 bg-[#191919] absolute bottom-1"/>
    </div>
}