import {SinglePageHeader} from "../../../ui/SinglePageHeader";
import useViewModel from "./SingleItemViewModel";
import {CavistaInput} from "../../../ui/CavistaInput";
import {CavistaTextArea} from "../../../ui/CavistaTextArea";
import {FormControlLabel, FormGroup} from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import {IOSSwitch} from "../../../ui/IOSSwitch";
import {useLottie} from "lottie-react";
import {defaultLoadingOptions} from "../../../ui/CommonItems";
import {SingleImageView} from "./SingleImageView";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export function SingleItemPage() {
    const {
        state,
        onYearChanged,
        onDescriptionChanged,
        onCountryChanged,
        onNameChanged,
        onTypeChanged,
        onDeleteImageClicked,
        onPriceChanged,
        uploadImagesResult,
        onDeleteAvatarClicked,
        onPriorityChanged,
        onReloadPageClick,
        onShowUserClicked,
        onEnableChanged,
        onPrimaryButtonClicked,
        onDeleteItemClicked
    } = useViewModel()
    const {View} = useLottie(defaultLoadingOptions);
    return <div className="bg-black">
        <SinglePageHeader title={state.title}/>
        {!state.isLoading && state.error == null && <div className="lg:h-[calc(100vh-80px)] h-full flex justify-center">
            <div className="m-auto lg:space-y-16 space-y-4">
                {(state.isAdmin && state.data.user !== null) && <div className="space-x-2 text-white text-center">
                    <p className="text-xl">Купил</p>
                    <div
                        onClick={onShowUserClicked}
                        className="text-lg cursor-pointer font-bold">{state.data.user?.fullName} за {state.data.soldPrice}€
                    </div>
                </div>
                }
                <div className="lg:flex justify-center lg:space-x-4 space-x-0">
                    <div className="space-y-4 w-[360px]">
                        <CavistaInput
                            onChanged={onNameChanged}
                            disabled={!state.isAdmin}
                            value={state.data.name}
                            text={"Название лота"}/>
                        <CavistaInput
                            onChanged={onCountryChanged}
                            disabled={!state.isAdmin}
                            value={state.data.country}
                            text={"Страна"}/>
                        <div className="flex justify-center space-x-2">
                            <CavistaInput
                                onChanged={onYearChanged}
                                disabled={!state.isAdmin}
                                value={state.data.year}
                                text={"Год"}/>
                            <div className="relative flex items-center">
                                <CavistaInput
                                    onChanged={onPriceChanged}
                                    disabled={!state.isAdmin}
                                    type={"number"}
                                    value={state.data.price}
                                    text={"Цена в"}/>
                                <div
                                    className="absolute text-white text-xl right-4 top-1/2 transform -translate-y-1/2">€
                                </div>
                            </div>
                        </div>
                        <CavistaTextArea
                            onChanged={onDescriptionChanged}
                            disabled={!state.isAdmin}
                            value={state.data.description}
                            text={"Описание лота"}/>
                    </div>
                    <div>
                        <div className="lg:flex lg:space-x-2 space-x-0">
                            <div className="relative lg:m-0">
                                {state.data.avatarFile?.source && <Zoom>
                                    <img
                                        src={state.data.avatarFile.source}
                                        id={state.data.avatarFile.viewId}
                                        className="w-[250px] h-[250px] m-auto lg:m-0 object-contain border-2 border-white rounded-lg"
                                    /></Zoom>}
                                {state.data.avatarFile?.isRealFile === true && state.isAdmin && <div
                                    onClick={() => onDeleteAvatarClicked(state.data.avatarFile?.viewId ?? "")}
                                    className="absolute bg-white border-2 border-black top-1 right-1 rounded-full">
                                    <CloseIcon style={{color: 'black'}}/>
                                </div>}
                                {state.isAdmin && <div>
                                    <label
                                        htmlFor="image_uploads"
                                        className="text-center text-white w-full pl-4 pb-2 pr-4 flex
                            justify-center cursor-pointer underline rounded-md">Добавить фото</label>
                                    <input
                                        type="file"
                                        onChange={(value) => uploadImagesResult(state.data.avatarFile?.viewId ?? "", value)}
                                        id="image_uploads"
                                        name="image_uploads"
                                        className="hidden"
                                        readOnly={true}
                                        accept=".jpg, .jpeg, .png, .webp, .heic"/>
                                </div>}
                            </div>
                            <div
                                className="flex lg:flex-col justify-center lg:mt-0 mt-4 lg:space-y-2 space-x-2 lg:space-x-0">
                                {(state.data.images[0].isRealFile || state.isAdmin) && <SingleImageView
                                    onDeleteAvatarClicked={onDeleteImageClicked}
                                    image={state.data.images[0]}
                                    isAdmin={state.isAdmin}
                                    uploadImageResult={uploadImagesResult}/>}
                                {(state.data.images[1].isRealFile || state.isAdmin) && <SingleImageView
                                    onDeleteAvatarClicked={onDeleteImageClicked}
                                    image={state.data.images[1]}
                                    isAdmin={state.isAdmin}
                                    uploadImageResult={uploadImagesResult}/>
                                }
                                {(state.data.images[2].isRealFile || state.isAdmin) && <SingleImageView
                                    isAdmin={state.isAdmin}
                                    onDeleteAvatarClicked={onDeleteImageClicked}
                                    image={state.data.images[2]}
                                    uploadImageResult={uploadImagesResult}/>
                                }
                            </div>
                        </div>
                        <h2 className="text-white">Тип алкоголя</h2>
                        <select
                            disabled={!state.isAdmin}
                            onChange={(type) => onTypeChanged(type.target.value)}
                            className="pt-2 pb-2 w-full bg-[#191919] text-white rounded-lg pl-4 pr-8 outline-none"
                            value={state.data.type}>
                            <option value={"wine"}>Вино</option>
                            <option value={"strongWine"}>Крепленое вино</option>
                            <option value={"sparklingWine"}>Игристое вино</option>
                            <option value={"strongAlcohol"}>Крепкий алкоголь</option>
                            <option value={"other"}>Другое</option>
                        </select>
                        {state.isAdmin && <div className="mt-4 space-y-2">
                            <h2 className="text-white">Приоритет в ленте</h2>
                            <select
                                disabled={!state.isAdmin}
                                onChange={(type) => onPriorityChanged(type.target.value)}
                                className="pt-2 pb-2 w-full bg-[#191919] text-white rounded-lg pl-4 pr-8 outline-none"
                                value={state.data.priority}>
                                <option value={"5"}>Самый высокий</option>
                                <option value={"4"}>Высокий</option>
                                <option value={"3"}>Выше среднего</option>
                                <option value={"2"}>Средний</option>
                                <option value={"1"}>Низкий</option>
                                <option value={"0"}>Самый низкий</option>
                            </select>
                        </div>}
                        {state.isAdmin && <FormGroup>
                            <FormControlLabel
                                className="text-white"
                                labelPlacement="start"
                                control={<IOSSwitch
                                    sx={{m: 1}}
                                    onChange={(isChecked) => onEnableChanged(isChecked.target.checked)}
                                    checked={state.data.isEnabled}/>
                                }
                                label="Скрыт в ленте (нет/да) "/>
                        </FormGroup>}
                    </div>
                </div>
                {state.isAdmin && <div className="flex justify-center space-x-2 w-full lg:pb-0 pb-4">
                    {state.data.id !== null && <button
                        onClick={onDeleteItemClicked}
                        className="w-full rounded-xl hover:border-red-600 border-red-800 border-2 text-red-800">Удалить
                    </button>}
                    <button
                        onClick={onPrimaryButtonClicked}
                        disabled={!state.isPrimaryButtonEnabled}
                        className={`w-full rounded-xl bg-[#6C1414] text-white pt-2 pb-2 pl-4 pr-4 ${state.isPrimaryButtonEnabled ? "bg-[#6C1414]" : "bg-gray-800"}`}>Сохранить
                    </button>
                </div>}
            </div>
        </div>}
        {state.isLoading && <div
            className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {View}
        </div>}
        {state.error !== null && <div
            className="absolute z-20 space-y-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <h2 className="text-white">Ошибка: {state.error}</h2>
            <button
                className="w-full bg-[#6C1414] rounded-md p-2 text-white active:opacity-100 focus:opacity-100"
                onClick={onReloadPageClick}>Перезагрузить
            </button>
        </div>}
    </div>
}