import {useDispatch, useSelector} from "react-redux";
import {authSlice} from "../../data/auth/AuthSlice";
import {CaveCavistaRoutes} from "../../routing/CaveCavistaRoutes";
import {useNavigate} from "react-router-dom";
import icPurchased from "../../assets/ic_purchased.svg"
import icExit from "../../assets/ic_logout.svg"
import {RootState} from "../../domain/store";

export function ProfileMenu() {

    const profileReducer = useSelector((state: RootState) => state.auth)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onLogoutClicked = () => {
        dispatch(authSlice.actions.clearAuth())
        navigate(CaveCavistaRoutes.auth, {replace: true})
    }

    return (
        <div
            className="absolute z-40 block top-10 right-8 bg-[#320909] divide-y divide-gray-100 rounded-lg shadow w-fit dark:bg-gray-700">
            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHoverButton">
                <h2 className="px-4 mb-2 text-white">{profileReducer.name}</h2>
                <div
                    onClick={() => navigate(CaveCavistaRoutes.purchased)}
                    className="flex justify-between items-center hover:bg-gray-400 hover:text-black cursor-pointer">
                    <div
                        className="block px-4 py-2">Покупки
                    </div>
                    <img src={icPurchased} alt="Search logo" className="w-6 h-6 mr-4"/>
                </div>
                <div className="flex justify-between items-center hover:bg-gray-400 hover:text-black cursor-pointer">
                    <button
                        onClick={onLogoutClicked}
                        className="block px-4 w-full text-start py-2">
                        Выход
                    </button>
                    <img src={icExit} alt="Search logo" className="w-6 h-6 mr-4"/>
                </div>
            </ul>
        </div>
    )
}