import {useEffect, useState} from "react";
import {initSingleUserPage} from "./SingleUserState";
import {useNavigate, useParams} from "react-router-dom";
import {
    useAddUserMutation,
    useDeleteUserMutation,
    useLoadUserMutation,
    useUpdateUserMutation
} from "../../../data/auth/AuthApi";
import {UserResponse} from "../../../data/auth/responses/UserResponse";
import {AuthExceptionsConverter} from "../../../domain/errors/AuthExceptionsConverter";
import {useAlert} from "../../main/AlertManager";
import {isNumber} from "../../../ui/CommonItems";


export default function SingleUserViewModel() {

    const {userId} = useParams()

    const [state, setState] = useState(initSingleUserPage())

    const navigate = useNavigate()

    const authErrorConverter = new AuthExceptionsConverter()
    const showAlert = useAlert()

    const [load] = useLoadUserMutation()
    const [addUser] = useAddUserMutation()
    const [deleteUser] = useDeleteUserMutation()
    const [updateUserMutation] = useUpdateUserMutation()

    useEffect(() => {
        if (isNumber(userId)) {
            fetchData()
        } else {
            setState({
                ...state,
                primaryButtonText: "Создать",
                isLoading: false
            })
        }
    }, [])

    function fetchData() {

        async function getSingleUser() {
            return await load({userId: Number(userId)}).unwrap()
        }

        getSingleUser().then((user: UserResponse) => {
            setState({
                ...state,
                data: {
                    id: user.id,
                    login: user.login,
                    fullName: user.name ?? "",
                    role: user.role,
                    title: "Редактировать",
                    address: user.address ?? "",
                    password: user.password,
                    contacts: user.contacts ?? "",
                },
                primaryButtonText: "Обновить",
                isLoading: false,
                error: null
            })
        }).catch((e) => {
            setState({
                ...state,
                isLoading: false,
                error: authErrorConverter.convert(e)
            })
        })
    }

    const onLoginChanged = (login: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                login: login
            }
        })
    }

    const onContactsChanged = (contacts: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                contacts: contacts
            }
        })
    }

    const onAddressChanged = (address: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                address: address
            }
        })
    }

    const onPasswordChanged = (password: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                password: password
            }
        })
    }

    const onFullNameChanged = (fullName: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                fullName: fullName
            }
        })
    }

    const onDeleteUserClicked = async () => {
        try {
            if (state.data.id) {
                const payload = await deleteUser({userId: state.data.id}).unwrap()
                if (payload.success) {
                    showAlert('Пользователь успешно удален', 'success')
                    navigate(-1)
                }
            }
        } catch (e) {
            showAlert('Ошибка при удалении пользователя', 'success')
        }
    }

    const onPrimaryButtonClicked = async () => {
        try {
            if (state.data.id !== null) {
                await updateUser()
            } else {
                await createUser()
            }
        } catch (e: any) {
            const error = authErrorConverter.convert(e)
            showAlert(error, 'error')
        }
    }

    async function createUser() {
        const payload = await addUser({
            login: state.data.login,
            name: state.data.fullName,
            contacts: state.data.contacts,
            password: state.data.password,
            address: state.data.address,
            role: state.data.role
        }).unwrap()
        if (payload.id != null) {
            showAlert('Пользователь создан', 'success')
            setState({
                ...state,
                primaryButtonText: "Обновить",
                data: {
                    ...state.data,
                    id: payload.id
                }
            })
        }
    }

    async function updateUser() {
        const payload = await updateUserMutation({
            login: state.data.login,
            name: state.data.fullName,
            contacts: state.data.contacts,
            password: state.data.password,
            address: state.data.address,
            role: state.data.role,
            id: state.data.id!!
        }).unwrap()
        if (payload.success) {
            showAlert('Пользователь обновлен', 'success')
        }
    }

    const onReloadPageClick = () => {

    }

    return {
        state,
        onLoginChanged,
        onAddressChanged,
        onContactsChanged,
        onPasswordChanged,
        onFullNameChanged,
        onReloadPageClick,
        onDeleteUserClicked,
        onPrimaryButtonClicked
    }

}