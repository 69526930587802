import {useEffect, useState} from "react";
import {initSingleItemState} from "./SingleItemState";
import {
    useAddImageMutation,
    useCreateProductMutation, useDeleteImageMutation,
    useDeleteProductMutation,
    useLoadSingleProductMutation,
    useUpdateProductMutation
} from "../../../data/products/ProductsApi";
import {getAvatar, isNumber} from "../../../ui/CommonItems";
import {useNavigate, useParams} from "react-router-dom";
import {ProductResponse} from "../../../data/products/responses/ProductResponse";
import {AuthExceptionsConverter} from "../../../domain/errors/AuthExceptionsConverter";
import {useAlert} from "../../main/AlertManager";
import {initImageStub, ProductSingleUiConverter} from "../converter/ProductSingleUiConverter";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../domain/store";
import {CaveCavistaRoutes} from "../../../routing/CaveCavistaRoutes";
import {
    addProduct,
    deleteProductAvatarSlice,
    deleteProductSlice,
    updateProductSlice
} from "../../../data/products/ProductsSlice";


export default function SingleItemViewModel() {

    const {productId} = useParams()

    const [state, setState] = useState(initSingleItemState())

    const dispatch = useDispatch()
    const profileReducer = useSelector((state: RootState) => state.auth)

    const [addImageMutation] = useAddImageMutation()
    const [deleteImageMutation] = useDeleteImageMutation()
    const [updateProductMutation] = useUpdateProductMutation()
    const [deleteProductMutation] = useDeleteProductMutation()
    const [createProductMutation] = useCreateProductMutation()
    const [loadProductMutation] = useLoadSingleProductMutation()

    const navigate = useNavigate()

    const authErrorConverter = new AuthExceptionsConverter()
    const showAlert = useAlert()

    useEffect(() => {
        if (isNumber(productId)) {
            fetchData()
        } else {
            setState({
                ...state,
                primaryButtonText: "Создать",
                isAdmin: true,
                isLoading: false
            })
        }
    }, [])

    function fetchData() {

        async function getSingleProduct() {
            return await loadProductMutation({productId: Number(productId)}).unwrap()
        }

        getSingleProduct().then((product: ProductResponse) => {
            const isAdminProfile: boolean = profileReducer.role === "admin"
            let title: string
            if (!isAdminProfile) {
                title = "Просмотр лота"
            } else {
                title = "Обновить лот"
            }
            setState({
                ...state,
                data: ProductSingleUiConverter(product, state),
                primaryButtonText: "Обновить",
                title: title,
                isAdmin: isAdminProfile,
                isLoading: false,
                error: null
            })
        }).catch((e) => {
            setState({
                ...state,
                isLoading: false,
                error: authErrorConverter.convert(e)
            })
        })
    }

    const onNameChanged = (name: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                name: name
            }
        })
    }

    const onCountryChanged = (country: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                country: country
            }
        })
    }

    const onYearChanged = (year: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                year: year
            }
        })
    }

    const onDescriptionChanged = (description: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                description: description
            }
        })
    }

    const onTypeChanged = (type: string | undefined) => {
        if (type) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    type: type
                }
            })
        }
    }

    const onPriorityChanged = (type: string | undefined) => {
        if (type) {
            setState({
                ...state,
                data: {
                    ...state.data,
                    priority: type.toString()
                }
            })
        }
    }

    const onPriceChanged = (price: string) => {
        setState({
            ...state,
            data: {
                ...state.data,
                price: price
            }
        })
    }

    const onDeleteItemClicked = async () => {
        try {
            if (state.data.id) {
                const payload = await deleteProductMutation({productId: state.data.id}).unwrap()
                if (payload.success) {
                    dispatch(deleteProductSlice(state.data.id))
                    navigate(-1)
                    showAlert("Лот удален", 'success')
                }
            }
        } catch (e) {
            showAlert("Ошибка при удалении лота", 'error')
        }
    }

    const onPrimaryButtonClicked = async () => {
        if (state.data.id == null) {
            await createProduct()
        } else {
            await updateProduct()
        }
    }

    const onReloadPageClick = () => {
        fetchData()
    }

    const onEnableChanged = (isEnabled: boolean) => {
        setState({
            ...state,
            data: {
                ...state.data,
                isEnabled: isEnabled
            }
        })
    }

    async function updateProduct() {
        try {
            setState({
                ...state,
                isPrimaryButtonEnabled: false,
            })
            const formData = getFormData(state.data.id)
            const payload = await updateProductMutation(formData).unwrap()
            if (payload) {
                setState({
                    ...state,
                    isPrimaryButtonEnabled: true,
                })
                dispatch(updateProductSlice(payload))
                showAlert("Лот обновлен", 'success')
            }
        } catch (e) {
            setState({
                ...state,
                isPrimaryButtonEnabled: true,
            })
            showAlert("Ошибка при обновлении лота", 'error')
        }
    }

    async function createProduct() {
        try {
            const formData = getFormData()
            setState({
                ...state,
                isPrimaryButtonEnabled: false,
            })
            const payload = await createProductMutation(formData).unwrap()
            if (payload) {
                setState({
                    ...state,
                    primaryButtonText: "Обновить",
                    isPrimaryButtonEnabled: true,
                    data: {
                        ...state.data,
                        avatarFile: {
                            ...state.data.avatarFile,
                            source: getAvatar(payload.avatarUrl),
                            file: null
                        },
                        images: state.data.images.map((image, index) => {
                            if (payload.images.at(index)) {
                                image.imageId = payload.images[index].imageId
                                image.source = payload.images[index].imageUrl
                            }
                            image.file = null
                            return image
                        }),
                        id: payload.id
                    }
                })
                dispatch(addProduct(payload))
                showAlert("Лот создан", 'success')
            }
        } catch (e) {
            console.log(e)
            setState({
                ...state,
                isPrimaryButtonEnabled: true,
            })
            showAlert("Ошибка при создании лота", 'error')
        }
    }

    function getFormData(id: number | null = null) {
        const formData = new FormData()
        if (id === null) {
            state.data.images.forEach((media) => {
                if (media.file && media.isRealFile) {
                    formData.append("image", media.file)
                }
            })
            if (state.data.avatarFile.file && state.data.avatarFile.isRealFile) {
                formData.append("avatar", state.data.avatarFile.file)
            }
        }
        if (id) formData.append("id", id.toString())
        formData.append("name", state.data.name)
        formData.append("isEnabled", state.data.isEnabled.toString())
        formData.append("price", state.data.price)
        formData.append("priority", state.data.priority)
        formData.append("description", state.data.description)
        formData.append("type", state.data.type)
        formData.append("year", state.data.year)
        formData.append("country", state.data.country)
        return formData
    }

    const onDeleteImageClicked = async (viewId: string, imageId: number) => {
        try {
            setState({
                ...state,
                data: {
                    ...state.data,
                    images: state.data.images.map((item) => {
                        if (item.viewId === viewId) {
                            const imgtag: HTMLImageElement = document.getElementById(viewId) as HTMLImageElement;
                            imgtag.src = getAvatar(null)
                            item.file = null
                            item.source = getAvatar(null)
                            item.isRealFile = false
                            return item
                        } else {
                            return item
                        }
                    }),
                }
            })
            const payload = await deleteImageMutation({
                imageId: imageId,
                productId: state.data.id,
                isAvatar: false
            }).unwrap()
            if (payload.success) {
                showAlert("Дополнительная картинка удалена", 'success')
            }
        } catch (e) {
            console.log(e)
            showAlert("Ошибка при удалении картинки", 'error')
        }
    }

    const onDeleteAvatarClicked = async (viewId: string) => {
        try {
            const imgtag: HTMLImageElement = document.getElementById(viewId) as HTMLImageElement;
            imgtag.src = getAvatar(null)
            if (!state.data.avatarFile.file) {
                const payload = await deleteImageMutation({
                    imageId: 0,
                    productId: state.data.id,
                    isAvatar: true
                }).unwrap()
                if (payload.success) {
                    console.log("avatar")
                    dispatch(deleteProductAvatarSlice(state.data.id))
                    showAlert("Аватарка лота удалена", 'success')
                }
            }
            setState({
                ...state,
                data: {
                    ...state.data,
                    avatarFile: initImageStub({imageId: 0, imageUrl: null}, "avatar")
                }
            })
        } catch (e) {
            showAlert("Ошибка при удалении аватарки лота", 'error')
        }
    }

    async function updateAvatar(file: File, viewId: string) {
        try {
            if (state.data.id) {
                const formData = new FormData()
                formData.append("image", file)
                formData.append("productId", state.data.id.toString())
                formData.append("isAvatar", "true")
                const payload = await addImageMutation(formData).unwrap()
                setState({
                    ...state,
                    data: {
                        ...state.data,
                        avatarFile: initImageStub({imageId: 0, imageUrl: payload.imageUrl}, "avatar")
                    }
                })
                updateImageFile(file, viewId)
                showAlert("Аватарка лота обновлена", 'success')
            } else {
                updateImageFile(file, viewId)
                setState({
                    ...state,
                    data: {
                        ...state.data,
                        avatarFile: {
                            ...state.data.avatarFile,
                            isRealFile: true,
                            file: file
                        }
                    }
                })
            }
        } catch (e) {
            console.log(e)
            showAlert("Ошибка при обновлении аватарки лота", 'error')
        }
    }

    async function updateSubImage(file: any, viewId: string, event: any) {
        try {
            if (state.data.id) {
                const formData = new FormData()
                formData.append("image", file)
                formData.append("productId", state.data.id.toString())
                formData.append("isAvatar", "false")
                const payload = await addImageMutation(formData).unwrap()
                setState({
                    ...state,
                    data: {
                        ...state.data,
                        images: state.data.images.map((item) => {
                            if (item.viewId === viewId) {
                                item.file = file
                                item.source = payload.imageUrl
                                item.isRealFile = true
                                item.name = event?.target?.result as string
                                return item
                            } else {
                                return item
                            }
                        }),
                    }
                })
                updateImageFile(file, viewId)
                showAlert("Картинка лота обновлена", 'success')
            } else {
                updateImageFile(file, viewId)
                setState({
                    ...state,
                    data: {
                        ...state.data,
                        images: state.data.images.map((media) => {
                            if (media.viewId === viewId) {
                                media.file = file
                                media.isRealFile = true
                            }
                            return media
                        })
                    }
                })
            }
        } catch (e) {
            console.log(e)
            showAlert("Ошибка при добавлении картинки лота", 'error')
        }
    }

    const uploadImagesResult = async (viewId: string, event: any) => {
        const file = event.target.files[0]
        if (file) {
            if (viewId === "avatar") {
                await updateAvatar(file, viewId)
            } else {
                await updateSubImage(file, viewId, event)
            }
        }
    }

    function updateImageFile(file: File, viewId: string) {
        const reader = new FileReader();
        const imgtag: HTMLImageElement = document.getElementById(viewId) as HTMLImageElement;
        if (imgtag != null) {
            reader.onload = function (event) {
                imgtag.src = event?.target?.result as string;
            };
            reader.readAsDataURL(file);
        }
    }

    const onShowUserClicked = () => {
        navigate(CaveCavistaRoutes.users + "/" + state.data.user?.id)
    }

    const onShowFullImageClick = (imageUrl: string | null) => {
        if (imageUrl) {
            setState({
                ...state,
                fullImageState: {
                    imageUrl: imageUrl,
                    isVisible: true
                }
            })
        }
    }

    const handleDialogVisibility = () => {
        setState({
            ...state,
            fullImageState: {
                imageUrl: "",
                isVisible: false
            }
        })
    }

    return {
        state,
        onCountryChanged,
        onDescriptionChanged,
        onNameChanged,
        onDeleteImageClicked,
        onReloadPageClick,
        handleDialogVisibility,
        onDeleteAvatarClicked,
        onPriorityChanged,
        onYearChanged,
        uploadImagesResult,
        onShowUserClicked,
        onEnableChanged,
        onTypeChanged,
        onPriceChanged,
        onDeleteItemClicked,
        onShowFullImageClick,
        onPrimaryButtonClicked
    }

}