import {useState} from "react";
import icProfile from "../assets/ic_profile.svg"
import {ProfileMenu} from "../features/main/ProfileMenu";


export function ProfileMenuWidget() {
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    return <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div
            className="inline-block cursor-pointer flex justify-center text-sm px-4 leading-none text-white">
            <img src={icProfile} alt="Profile logo" className="w-6 h-6"/>
        </div>
        {isDropdownVisible && <ProfileMenu/>}
    </div>
}