import {useState} from "react";
import {initSingleItemState, SingleItemState} from "./SingleItemState";


export default function SingleItemDialogViewModel() {

    const [state, setState] = useState<SingleItemState>(initSingleItemState())

    const handleDialogVisibility = () => {
        setState({
            ...state,
            imageUrl: "",
            isVisible: false
        })
    }

    const onShowFullImageClick = (imageUrl: string | null) => {
        if (imageUrl) {
            setState({
                ...state,
                imageUrl: imageUrl,
                isVisible: true
            })
        }
    }

    return {
        state,
        handleDialogVisibility,
        onShowFullImageClick
    }

}