import {CavistaInput} from "../../ui/CavistaInput";

export function SearchPage() {

    return (<div className="ml-4 mr-4">
        <div className="mt-4">
            <h2 className="text-white text-xl mb-2">Поиск</h2>
            <div className="flex justify-center items-center">
                <CavistaInput
                    value={""}
                    text={"Поиск вина, пользователей"}
                    onChanged={() => {}}
                />
                <button className="text-white ml-2 w-[120px] bg-[#6C1414] h-[56px] rounded-xl pt-0.5 pb-0.5 pl-2 pr-2">
                    Поиск
                </button>
            </div>
        </div>
    </div>)
}