import {ItemUi} from "../../products/main/ItemUi";

export interface BottomProductsComponentProps {
    items: ItemUi[]
    currentItem: ItemUi
    onItemClicked: (item: ItemUi) => void
}

export function BottomProductsComponent({items, onItemClicked, currentItem}: BottomProductsComponentProps) {
    return (<div className="overflow-x-hidden w-full lg:max-w-[calc(100vw-640px)]">
        <div className="overflow-scroll overflow-y-hidden [&>div]:flex-shrink-0">
            <div className="flex space-x-2 row">
                {items.map((item, index) => <div
                    onClick={() => onItemClicked(item)} key={item.id}
                    className="min-w-[150px] w-[150px] h-full hover:bg-gray-600 hover:rounded-lg rounded-none">
                    <h3 className="text-white text-center">ЛОТ № {item.index}</h3>
                    <div className="h-fit flex justify-center relative">
                        <img className="rounded-md object-contain w-[150px] h-[150px]" src={item.imageUrl}/>
                        {item.id === currentItem.id && <div className="w-full h-2 bg-red-600 absolute bottom-0"/>}
                    </div>
                </div>)}
            </div>
        </div>
    </div>)
}