import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import {persistReducer, persistStore} from "redux-persist";
import {thunk} from 'redux-thunk';
import {AuthApi} from "../data/auth/AuthApi";
import {authReducer} from "../data/auth/AuthSlice";
import {ProductsApi} from "../data/products/ProductsApi";
import {ConfigApi} from "../data/config/ConfigApi";
import {productsReducer} from "../data/products/ProductsSlice";

const persistConfig = {
    key: "root", storage,
};

const rootReducer = combineReducers({
    [AuthApi.reducerPath]: AuthApi.reducer,
    [ProductsApi.reducerPath]: ProductsApi.reducer,
    [ConfigApi.reducerPath]: ConfigApi.reducer,
    auth: authReducer,
    products: productsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(
        thunk,
        AuthApi.middleware,
        ConfigApi.middleware,
        ProductsApi.middleware
    )
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;