import {ConnectedUser} from "../AuctionState";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog} from "@mui/material";
import React from "react";

export interface ActiveUsersComponentProps {
    users: ConnectedUser[]
    isVisible: boolean,
    handleVisibility: () => void
}

export function ActiveUsersComponent({users, isVisible, handleVisibility}: ActiveUsersComponentProps) {
    return <Dialog
        open={isVisible}
        fullScreen={false}
        onClose={handleVisibility}>
        <div
            className="bg-[#320909] p-8 lg:h-full h-fit relative text-white text-center">
            <h2>Пользователи</h2>
            <div
                onClick={() => handleVisibility()}
                className="absolute right-4 top-4">
                <CloseIcon/>
            </div>
            <div className="pt-4 lg:grid-cols-4 lg:grid flex-col space-y-2 lg:gap-2 lg:space-y-0">
                {users.map((user) => <div key={user.userId} className="lg:w-fit w-full bg-black rounded-xl p-3 space-x-2">
                    <h2>{user.username}</h2>
                </div>)
                }
            </div>
        </div>
    </Dialog>
}