import useViewModel from "./SettingsViewModel"
import {DayPicker} from "react-day-picker";
import React from "react";
import {CavistaInput} from "../../ui/CavistaInput";
import {ru} from "date-fns/locale";
import {useLottie} from "lottie-react";
import {defaultLoadingOptions} from "../../ui/CommonItems";

export function SettingsPage() {
    const {
        state,
        onDateChanged,
        onDateTextChanged,
        onTimeChanged,
        onDelayChanged,
        onStepChanged,
        onRateChanged,
        onBetTimerChanged,
        onReloadPageClicked,
        onSaveClicked,
        onFinishAuctionClicked,
        onDeleteAllUsersFromProductsClicked
    } = useViewModel()
    const {View} = useLottie(defaultLoadingOptions)
    return (<div className="relative">
        <h2 className="lg:block hidden absolute text-white left-1/2 transform -translate-x-1/2 top-2">ДАТА И ВРЕМЯ
            НАЧАЛА ТОРГОВ</h2>
        <h2 className="lg:hidden block text-center text-white top-2">ДАТА И ВРЕМЯ НАЧАЛА ТОРГОВ</h2>
        <div className="lg:h-[calc(100vh-80px)] bg-black w-full flex justify-center">
            {!state.isLoading && state.error == null && <div className="w-full m-auto p-4 lg:space-y-16">
                <div className="flex lg:flex-row flex-col m-auto lg:w-fit w-full lg:space-x-4">
                    <div>
                        <div className="flex lg:flex-row flex-col lg:space-x-4">
                            <div className="space-y-2">
                                <h2 className="text-white">Дата начала торгов</h2>
                                <CavistaInput
                                    onChanged={onDateTextChanged}
                                    text={"Укажите дату торгов"}
                                    value={state.data.dateText}
                                />
                            </div>
                            <div className="space-y-2 text-white">
                                <h2>Время начала торгов</h2>
                                <CavistaInput
                                    onChanged={onTimeChanged}
                                    text={"Укажите время 2:30"}
                                    value={state.data.time}
                                />
                            </div>
                        </div>
                        <DayPicker
                            mode="single"
                            selected={state.data.date}
                            locale={ru}
                            onSelect={(date) => onDateChanged(date)}
                            showOutsideDays
                            className="border-0"
                            classNames={{
                                caption: "flex justify-center py-2 mb-4 relative items-center",
                                caption_label: "text-sm font-medium text-white",
                                nav: "flex items-center",
                                nav_button:
                                    "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                nav_button_previous: "absolute left-1.5 bg-white",
                                nav_button_next: "absolute right-1.5 bg-white",
                                table: "w-full border-collapse",
                                head_row: "flex font-medium text-white",
                                head_cell: "m-0.5 w-9 font-normal text-sm",
                                row: "flex w-full mt-2",
                                cell: "text-white rounded-md h-9 w-full text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20" +
                                    " [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md" +
                                    " last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                day: "h-9 w-9 p-0 font-normal",
                                day_range_end: "day-range-end",
                                day_selected:
                                    "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                                day_today: "rounded-md bg-gray-600 text-white",
                                day_outside:
                                    "day-outside text-white opacity-50 aria-selected:bg-gray-500 aria-selected:text-white aria-selected:bg-opacity-10",
                                day_disabled: "text-white opacity-50",
                                day_hidden: "invisible",
                            }}
                        />
                    </div>
                    <div>
                        <div className="space-y-4">
                            <div className="space-y-2">
                                <h2 className="text-white">Задержка перед началом (сек)</h2>
                                <CavistaInput
                                    onChanged={onDelayChanged}
                                    text={"30 сек"}
                                    value={state.data.delay}
                                />
                            </div>
                            <div className="space-y-2">
                                <h2 className="text-white">Таймер ставки</h2>
                                <CavistaInput
                                    onChanged={onBetTimerChanged}
                                    text={"10 сек"}
                                    value={state.data.betTime}
                                />
                            </div>
                            <div className="space-y-2">
                                <h2 className="text-white">Шаг ставки</h2>
                                <CavistaInput
                                    onChanged={onStepChanged}
                                    text={"20€"}
                                    value={state.data.step}
                                />
                            </div>
                            <div className="space-y-2">
                                <h2 className="text-white">Курс 1€</h2>
                                <CavistaInput
                                    onChanged={onRateChanged}
                                    text={"96.4"}
                                    value={state.data.rate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex justify-center lg:pt-0 pt-4 space-x-2 ml-16 mr-16">
                        <button
                            onClick={onSaveClicked}
                            className="w-full rounded-xl bg-[#6C1414] text-white pt-2 pb-2 pl-4 pr-4">Сохранить
                        </button>
                        <button
                            onClick={onDeleteAllUsersFromProductsClicked}
                            className="w-full rounded-xl bg-[#6C1414] text-white pt-2 pb-2 pl-4 pr-4">Освободить лоты
                        </button>
                        <button
                            onClick={onFinishAuctionClicked}
                            disabled={!state.data.isAuctionStarted}
                            className={`w-full rounded-xl text-white pt-2 pb-2 pl-4 pr-4 ${state.data.isAuctionStarted ? ' bg-[#6C1414]' : 'bg-gray-800'} `}>Завершить
                            торги
                        </button>
                    </div>
                    <div className="text-[#818181] ml-16 mr-16 text-center mt-4">
                        {!state.data.canStartAuction && <p>Вы не можете начать торги, так как нет свободных лотов</p>}
                        <p>После нажатия на "Сохранить" на экране аукциона появится точное время и дата, которую вы
                            указали</p>
                    </div>
                </div>
            </div>}
            {state.isLoading && <div
                className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                {View}
            </div>}
            {state.error !== null && <div
                className="absolute z-20 text-white space-y-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <h2 className="text-white">Ошибка: {state.error}</h2>
                <button
                    className="w-full bg-[#6C1414] rounded-md p-2 text-white active:opacity-100 focus:opacity-100"
                    onClick={() => onReloadPageClicked()}>Перезагрузить
                </button>
            </div>}
        </div>
    </div>)
}