import {ProductResponse} from "../../../data/products/responses/ProductResponse";
import {ItemUi} from "../main/ItemUi";
import {getAvatar} from "../../../ui/CommonItems";
import {UserUiConverter} from "../../users/converter/UserUiConverter";

export function ProductUiConverter(item: ProductResponse, showSoldPrice: boolean = false): ItemUi {
    return {
        id: item.id,
        imageUrl: getAvatar(item.avatarUrl),
        country: item.country ?? "",
        name: item.name,
        year: item.year ?? "",
        price: item.price ?? "",
        priceNumber: Number(item.price),
        soldPrice: item.soldPrice ?? 0,
        user: item.user ? UserUiConverter(item.user) : null,
        description: item.description ?? "",
        isEnabled: item.isEnabled,
        index: item.indexName,
        showSoldPrice: showSoldPrice,
        images: item.images.map((image) => getAvatar(image.imageUrl)).concat(getAvatar(item.avatarUrl))
    }
}