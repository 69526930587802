import {createSlice} from "@reduxjs/toolkit";

const initialState: AuthState = {
    token: "",
    role: "",
    name: "",
    password: "",
    login: "",
    address: "",
    id: -1
}

export interface AuthState {
    token: string
    role: string
    name: string
    login: string
    address: string
    password: string
    id: number
}

export const authSlice = createSlice({
    name: "auth", initialState: initialState, reducers: {
        updateAuth: (state, action) => {
            console.log(action)
            state.token = action.payload.token
            state.name = action.payload.name
            state.role = action.payload.role
            state.password = action.payload.password
            state.address = action.payload.address
            state.login = action.payload.login
            state.id = action.payload.id
        },
        clearAuth: (state) => {
            state.token = ""
        }
    },
});

export const {updateAuth, clearAuth} = authSlice.actions

export const authReducer = authSlice.reducer