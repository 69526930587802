import React from "react";

export interface AuctionFinishedComponentProps {
    isAdmin: boolean
    onShowProductsClicked: () => void
}

export function AuctionFinishedComponent({isAdmin, onShowProductsClicked}: AuctionFinishedComponentProps) {
    return (<div
        className="absolute z-20 top-1/2 left-1/2 lg:w-fit w-full text-white text-center transform -translate-x-1/2 -translate-y-1/2">
        <div className="space-y-4 bg-[#320909] p-4 lg:p-8 lg:rounded-lg rounded-none">
            <h2>Аукцион завершился!</h2>
            <div>
                <h3>ВАШИ ПОКУПКИ НАХОДЯТСЯ В ПРОФИЛЕ</h3>
                <h3>В БЛИЖАЙШЕЕ ВРЕМЯ С ВАМИ СВЯЖЕТСЯ МЕНЕДЖЕР</h3>
            </div>
            <button
                onClick={onShowProductsClicked}
                className="w-fit rounded-xl bg-[#6C1414] text-white pt-2 pb-2 pl-4 pr-4">{isAdmin ? "Перейти к продажам" : "Перейти к покупкам"}
            </button>
        </div>
        {isAdmin && <div className="w-3/4 m-auto text-[#818181]">
            <p className="text-center">Чтобы заново запустить аукцион нажмите в настройках "Сохранить" текущие настройки</p>
        </div>}
    </div>)
}