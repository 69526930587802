import React from "react";
import {ItemUi} from "../../products/main/ItemUi";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export interface FullProductComponentProps {
    product: ItemUi
    size: number
}

export function FullProductComponent({product, size}: FullProductComponentProps) {
    return (<div className="w-full h-full">
        <div className="bg-[#320909] rounded-lg h-full text-white text-center">
            <div className="flex justify-center items-center h-full">
                <div className="flex justify-center items-center pt-1">
                    <div className="ml-1 mr-1 relative max-w-full">
                        <div className="lg:flex-col flex-col flex items-center justify-center lg:space-x-0 space-x-2">
                            <h2 className="text-xl">ЛОТ № {product.index} из {size}</h2>
                            <h3 className="text-xl">{product.name}</h3>
                        </div>
                        <div>
                            <Zoom>
                                <img
                                    src={product.imageUrl}
                                    className="max-h-[340px] pl-2 h-[340px] rounded-lg m-auto max-w-[340px] w-[340px] object-contain"/>
                            </Zoom>
                            <div className="ml-2 mr-2">
                                <h2 className="text-lg">{product.year}</h2>
                                <h2 className="text-lg">{product.country}</h2>
                                {product.description !== "" && <div className="bg-[#6C1414] h-[4px] w-[200px] m-auto"/>}
                                <h2 className="text-[#818181] text-base mb-2 pt-2">{product.description}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>)

}