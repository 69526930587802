import CloseIcon from "@mui/icons-material/Close";

export interface UserWinProductComponentProps {
    closeDialog: () => void
}

export function NextProductComponent({closeDialog}: UserWinProductComponentProps) {
    return (<div className="absolute top-0 w-full h-full">
        <div className="absolute w-full h-full z-10 opacity-60 bg-black"/>
        <div
            className="absolute z-50 absolute top-1/4 lg:top-1/2 border-4 border-black left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#320909] w-[360px] h-fit rounded-lg">
            <div className="space-y-4 p-4 mt-4 text-center text-white">
                <h2 className="text-xl text-center">Переход к следующему лоту</h2>
                <p>Это окно закроется само через 5 секунд и начнется следующий лот</p>
            </div>
            <div
                onClick={closeDialog}
                className="absolute top-2 right-2">
                <CloseIcon/>
            </div>
        </div>
    </div>)
}