import {ProductImageResponse, ProductResponse} from "../../../data/products/responses/ProductResponse";
import {SingleItemImageUi, SingleItemUi} from "../single/SingleItemUi";
import {getAvatar} from "../../../ui/CommonItems";
import {SingleItemState} from "../single/SingleItemState";
import {UserUiConverter} from "../../users/converter/UserUiConverter";

export function initImageStub(product: ProductImageResponse, viewId: string): SingleItemImageUi {
    return {
        source: getAvatar(product.imageUrl),
        file: null,
        name: null,
        viewId: viewId,
        imageId: product.imageId,
        isRealFile: product.imageUrl !== null
    }
}

export function ProductSingleUiConverter(item: ProductResponse, state: SingleItemState): SingleItemUi {

    const images = state.data.images.map((image, index) => {
        if (item.images.length - 1 > index) {
            image.imageId = item.images[index].imageId
            image.source = item.images[index].imageUrl
            image.isRealFile = true
            return image
        } else {
            return image
        }
    })

    return {
        id: item.id,
        avatarFile: {
            source: getAvatar(item.avatarUrl),
            file: null,
            name: null,
            viewId: "avatar",
            imageId: 0,
            isRealFile: item.avatarUrl !== null
        },
        priority: item.priority.toString(),
        user: item.user ? UserUiConverter(item.user) : null,
        images: images,
        soldPrice: item.soldPrice,
        type: item.type,
        description: item.description ?? "",
        country: item.country ?? "",
        name: item.name,
        year: item.year ?? "",
        price: item.price ?? "",
        isEnabled: item.isEnabled
    }
}