
export interface SettingsState {
    isLoading: boolean
    data: SettingsData
    error: string | null
}

export interface SettingsData {
    date: Date | undefined
    dateText: string
    isAuctionStarted: boolean
    canStartAuction: boolean
    time: string
    step: string
    rate: string
    betTime : string
    delay: string
}

export function initSettingsState() : SettingsState {
    return {
        isLoading: true,
        data: {
            date: undefined,
            dateText: "",
            rate: "",
            canStartAuction: false,
            betTime: "",
            delay: "",
            step: "",
            time: "",
            isAuctionStarted: false
        },
        error: null
    }
}